import {
  axiosInstance as axios,
  paramsSerializerForDotnet,
} from "@/core/services/api.client";
const prefix = "api/v1/client/";
const prefixV2 = "api/v2/client/";

export type PaymentServiceResType = {
  deposit: any[];
  withdrawal: any[];
};

export type WalletsResType = {
  id: number;
  type: number;
  balance: number;
  sequence: number;
  currencyId: number;
  talliedOn: string;
};

export type TradeAccountResType = {
  uid: number;
  serviceId: number;
  accountNumber: number;
  currencyId: number;
  lastSyncedOn: string;
  leverage: number;
  balance: number;
  balanceInCents: number;
};

export type DepositReqType = {
  amount: number;
  currencyId: number;
  paymentServiceId: number;
  fundType: number;
  targetTradeAccountUid: number | undefined;
  note: string | undefined;
  request: object | undefined;
  paymentServiceCategoryName: string | undefined;
};

export type WithdrawalReqType = {
  amount: number;
  walletId: number | undefined;
  paymentServiceId: number;
  request: object | undefined;
};

export type TransferReqType = {
  walletId: number;
  tradeAccountUid: number;
  amount: number;
};

export default {
  queryAccountWithdrawalV2: async (uid: number, criteria?: any) =>
    (
      await axios.get(prefixV2 + "account/" + uid + "/withdrawal", {
        params: criteria,
      })
    ).data,

  getDepositGuide: async (uid: number, depositHashId?: any) =>
    (
      await axios.get(
        prefixV2 + "account/" + uid + "/deposit/" + depositHashId + "/guide"
      )
    ).data,

  queryDepositV2: async (uid: number, criteria?: any) =>
    (
      await axios.get(prefixV2 + "account/" + uid + "/deposit", {
        params: criteria,
      })
    ).data,

  getUploadReceiptInfo: async (paymentHashId: string) =>
    (await axios.get(prefix + "payment/" + paymentHashId + "/guide")).data,

  queryDeposit: async (criteria?: any) =>
    (
      await axios.get(prefix + "deposit", {
        params: criteria,
        paramsSerializer: paramsSerializerForDotnet,
      })
    ).data,

  getDeposit: async (id: number) =>
    (await axios.get(prefix + "deposit/" + id)).data,

  postDeposit: async (formData: DepositReqType) =>
    (await axios.post(prefix + "deposit", formData)).data,

  putDeposit: async (id: number, data: any) =>
    (await axios.put(prefix + "deposit/" + id, data)).data,

  cancelDeposit: async (id: number) =>
    (await axios.put(prefix + "deposit/" + id + "/cancel")).data,

  queryWithdrawal: async (criteria?: any) =>
    (await axios.get(prefix + "withdrawal", { params: criteria })).data,

  postWithdrawal: async (data: any) =>
    (await axios.post(prefix + "withdrawal", data)).data,

  putWithdrawal: async (id: number, data: any) =>
    (await axios.put(prefix + "withdrawal/" + id, data)).data,

  cancelWithdrawal: async (id: number) =>
    (await axios.put(prefix + "withdrawal/" + id + "/cancel")).data,

  queryTransfer: async (criteria?: any) =>
    (await axios.get(prefix + "transaction", { params: criteria })).data,

  postTransferToWallet: async (data: any) =>
    (await axios.post(prefix + "transaction/to/wallet", data)).data,

  postTransferToTradeAccount: async (data: any) =>
    (await axios.post(prefix + "transaction/to/trade-account", data)).data,

  getWallets: async () => (await axios.get(prefix + "wallet")).data,

  getWalletsV2: async () => (await axios.get(prefixV2 + "wallet")).data,

  getTradeAccounts: async (criteria?: any) =>
    (
      await axios.get(prefix + "trade-account", {
        params: criteria,
        paramsSerializer: paramsSerializerForDotnet,
      })
    ).data,

  queryClientTransactionView: async (criteria?: any) =>
    (
      await axios.get(prefix + "transaction/query", {
        params: criteria,
      })
    ).data,

  queryWalletWithdrawView: async (walletId: number, criteria?: any) =>
    (
      await axios.get(prefix + `wallet/${walletId}/withdrawal`, {
        params: criteria,
      })
    ).data,

  queryWalletTransferView: async (walletId: number, criteria?: any) =>
    (
      await axios.get(prefix + `wallet/${walletId}/transfer`, {
        params: criteria,
      })
    ).data,

  queryWalletTransactionView: async (walletId: number, criteria?: any) =>
    (
      await axios.get(prefix + `wallet/${walletId}/transaction`, {
        params: criteria,
      })
    ).data,

  getDepositReceiptFile: async (id: number) =>
    (await axios.get(prefix + "deposit/" + id + "/receipt")).data,

  getDepositReceiptFileV2: async (id: number, hashId: any) =>
    (
      await axios.get(
        prefixV2 + "account/" + id + "/deposit/" + hashId + "/receipt"
      )
    ).data,

  postDepositReceiptFile: async (id: number, file: any) =>
    (await axios.post(prefix + "deposit/" + id + "/receipt", file)).data,

  postDepositReceiptFileV2: async (id: number, hashId: any, file: any) =>
    (
      await axios.post(
        prefixV2 + "account/" + id + "/deposit/" + hashId + "/receipt",
        file
      )
    ).data,

  getMethodInstructionV2: async (id: number) =>
    (await axios.get(prefixV2 + "payment-method/" + id + "/instruction")).data,

  getOrderReference: async (id: number) =>
    (await axios.get(prefix + "deposit/" + id + "/reference")).data,

  getExchangeRate: async (criteria?: any) =>
    (await axios.get(prefix + "exchange-rate", { params: criteria })).data,

  getDepositExchangeRate: async (from: number, to: number) =>
    (await axios.get(prefix + "deposit/" + from + "/to/" + to)).data,

  getUploadReceiptV2: async (uid: number, depositHashId: number) =>
    (
      await axios.get(
        prefixV2 + "account/" + uid + "/deposit/" + depositHashId + "/receipt"
      )
    ).data,

  postUploadReceiptV2: async (uid: number, depositHashId: number, file: any) =>
    (
      await axios.post(
        prefixV2 + "account/" + uid + "/deposit/" + depositHashId + "/receipt",
        file
      )
    ).data,

  queryWalletWithdrawV2: async (hashId: any, criteria?: any) =>
    (
      await axios.get(prefixV2 + `wallet/${hashId}/withdrawal`, {
        params: criteria,
      })
    ).data,

  queryWalletTransferV2: async (hashId: any, criteria?: any) =>
    (
      await axios.get(prefixV2 + `wallet/${hashId}/transfer`, {
        params: criteria,
      })
    ).data,

  queryWalletAdjustV2: async (hashId: any, criteria?: any) =>
    (
      await axios.get(prefixV2 + `wallet/${hashId}/adjust`, {
        params: criteria,
      })
    ).data,

  queryWalletRefundV2: async (hashId: any, criteria?: any) =>
    (
      await axios.get(prefixV2 + `wallet/${hashId}/refund`, {
        params: criteria,
      })
    ).data,

  queryWalletRebateV2: async (hashId: any, criteria?: any) =>
    (
      await axios.get(prefixV2 + `wallet/${hashId}/rebate`, {
        params: criteria,
      })
    ).data,
};
