export default {
  "en-us": {
    "1": {
      id: 1,
      question: "What does the term ‘equity’ refer to?",
      options: {
        a: "Equity is your account balance plus the floating profit (or loss) of all your open positions",
        b: "Equity is the TOTAL amount of margin currently in use to maintain all open positions",
        c: "Equity is the gap between the bid and the ask price",
        d: "Equity is the total margin required to hold all current open positions",
      },
    },
    "2": {
      id: 2,
      question: "What is “the spread”?",
      options: {
        a: "The profit from a trade",
        b: "The loss from a trade",
        c: "The difference between the bid and the ask price",
        d: "The difference between the Market Open and Market Close price",
      },
    },
    "3": {
      id: 3,
      question: "When are you likely to be asked to add funds to your account?",
      options: {
        a: "If the market moves against you, and it results in a margin call",
        b: "When I have closed my position for a profit",
        c: "When someone suggests adding funds because you are trading profitably",
        d: "When there is a promotion for depositing margin",
      },
    },
    "4": {
      id: 4,
      question: "When you trade a share CFD, which statement is true?",
      options: {
        a: "You have the right to attend the annual general meeting",
        b: "You take on less risk than trading physical shares",
        c: "You don’t own the underlying stock directly in your name",
        d: "You have share voting rights",
      },
    },
    "5": {
      id: 5,
      question: "Which of the following best describes ‘gapping’?",
      options: {
        a: "Gapping is the break between prices on a chart that occurs when the price of a market makes a sharp move up or down with no trading occurring between those prices",
        b: "The change in your account balance after making a large deposit or withdrawal",
        c: "The ‘gap’ is the difference between the buy and the sell price",
        d: "The ‘gap’ is the difference between the Market Open and Market Close price",
      },
    },
    "6": {
      id: 6,
      question:
        "Who is ultimately responsible for monitoring and managing your trading account?",
      options: {
        a: "The Company’s risk team",
        b: "It is my responsibility to monitor and manage my trading account",
        c: "My trading account does not require management or monitoring",
        d: "My financial advisor should monitor my trading account",
      },
    },
    "7": {
      id: 7,
      question: "Which of the following is NOT a major currency pair?",
      options: {
        a: "USDJPY",
        b: "NZDJPY",
        c: "EURUSD",
        d: "USDGBP",
      },
    },
    "8": {
      id: 8,
      question: "What is market volatility?",
      options: {
        a: "When there is little or no movement in price over a period of time",
        b: "When there are large movements in price over a short period of time",
        c: "Volatility is a measure of how much profit you will make on a trade",
        d: "When the market crashes",
      },
    },
    "9": {
      id: 9,
      question:
        "What is the term used to describe trading with borrowed capital?",
      options: {
        a: "Margin trading",
        b: "Profitable trading",
        c: "Options trading",
        d: "Share trading",
      },
    },
    "10": {
      id: 10,
      question: "What does a stop loss do?",
      options: {
        a: "Guarantees you will not lose money",
        b: "Pays you a bonus if it gets activated",
        c: "Limits your loss by entering into a trade at the next available price",
        d: "It refunds all of your losses",
      },
    },
    "11": {
      id: 11,
      question: "What are the risks of trading leveraged products?",
      options: {
        a: "You could potentially lose more money than you deposited in your account",
        b: "Due to the leverage element, you may suffer losses normally associated with a much larger investment",
        c: "Highly leveraged transactions are subject to significant changes in value as a result of relatively small changes in the value or level of an underlying asset",
        d: "All of the above",
      },
    },
    "12": {
      id: 12,
      question:
        "You decide to buy A$10,000 of AUDUSD at 0.74000. A few weeks later, the sell price stands at 0.72000. If you close your position at 0.72000, how much profit/loss will you make in USD (not counting any charges or commission payments)?",
      options: {
        a: "$200 loss",
        b: "$200 profit",
        c: "$2,000 profit",
        d: "$2,000 loss",
      },
    },
    "13": {
      id: 13,
      question:
        "Which of the following situations would require you to fund your account?",
      options: {
        a: "When you are making a profit",
        b: "The market is going against you, and you are nearing auto close out, but you want to keep your positions open",
        c: "Your positions have been closed, and you do not want to open any more",
        d: "When you want to close your account and cover all of your outstanding fees",
      },
    },
    "14": {
      id: 14,
      question:
        "Where would you place a stop loss (SL) for a buy (long) trade?",
      options: {
        a: "Above your entry price",
        b: "Below your entry price",
        c: "At the same rate as the take profit",
        d: "You can only place a stop loss on a sell (short) trade",
      },
    },
    "15": {
      id: 15,
      question:
        "When trading CFD shares on margin, which of the following statements are correct?",
      options: {
        a: "You will own a percentage of the company you have placed a trade on",
        b: "Your profits and losses will be magnified relative to the margin requirement",
        c: "The CFD trade will entitle you to take part in shareholder votes",
        d: "You will always receive dividends from your CFD market maker",
      },
    },
    "16": {
      id: 16,
      question:
        "You hold a 2oz long (buy) position in gold, and the market mid-price is currently $1,800. If the margin requirement for this trade is 5%, what is the total margin requirement for this trade?",
      options: {
        a: "$90",
        b: "$180",
        c: "$900",
        d: "$36",
      },
    },
    "17": {
      id: 17,
      question:
        "The size of one contract of Crude oil is 1000 barrels. It is currently trading at $50 per barrel, and you decide to buy five contracts for $250,000 ($50 x 5000 barrels). Later, the price of Crude oil drops to $45 per barrel, and you decide to close your position. How much profit/loss have you made (ignoring any charges or commission payments)?",
      options: {
        a: "$50,000 profit",
        b: "$25,000 loss",
        c: "$50,000 loss",
        d: "$25,000 profit",
      },
    },
    "18": {
      id: 18,
      question: "What does a 5:1 reward-to-risk ratio mean?",
      options: {
        a: "If you risk $250, you can lose $1,250",
        b: "If you risk $350, you have a chance of winning $1,750",
        c: "You stand to win $5,000 if you risk $25,000",
        d: "You stand to win $5,000 if you risk $1,000",
      },
    },
    "19": {
      id: 19,
      question: "When trading a leverage product, which statement is true?",
      options: {
        a: "Both profits and losses can be magnified",
        b: "Only profits can be magnified",
        c: "Only losses can be magnified",
        d: "Nothing is magnified",
      },
    },
    "20": {
      id: 20,
      question: "Why would you use a stop loss?",
      options: {
        a: "To open a new position",
        b: "To save on transaction fees",
        c: "To limit your loss on a trade",
        d: "To make maximum profit on a trade",
      },
    },
    "21": {
      id: 21,
      question:
        "You have a current account balance of $50,000, account equity of $40,000, and only one open position in AUDUSD with a margin requirement of $30,000. The margin call level is 100%. Under which of the following circumstances will you receive a margin call?",
      options: {
        a: "When your account balance is lower than $40,000",
        b: "When your account balance is lower than $50,000",
        c: "When your account equity is lower than $30,000",
        d: "When your account equity is $0",
      },
    },
    "22": {
      id: 22,
      question:
        "AUDUSD contracts have a margin requirement of 4%. You opened a position in AUDUSD with a notional value of A$100,000. What is the margin requirement?",
      options: {
        a: "A$4,000",
        b: "A$40,000",
        c: "A$400",
        d: "A$40",
      },
    },
    "23": {
      id: 23,
      question:
        "You hold a 3 oz. long (buy) position in Australian dollar spot gold, and the market mid-price is currently A$2,200. If the margin requirement for this trade is 5%, what is the total margin requirement for this trade?",
      options: {
        a: "A$2,200",
        b: "A$330",
        c: "A$3,300",
        d: "A$220",
      },
    },
  },
  "zh-cn": {
    "1": {
      id: 1,
      question: "术语“权益”是指什么？",
      options: {
        a: "权益是您账户余额加上所有持仓的浮动盈亏",
        b: "权益是用于维持所有持仓所需的总保证金金额",
        c: "权益是买价和卖价之间的差距",
        d: "权益是持有所有当前持仓所需的总保证金",
      },
    },
    "2": {
      id: 2,
      question: "什么是“点差”？",
      options: {
        a: "交易的利润",
        b: "交易的损失",
        c: "买价和卖价之间的差距",
        d: "市场开盘价和收盘价之间的差距",
      },
    },
    "3": {
      id: 3,
      question: "在什么情况下可能要求您添加资金到您的账户？",
      options: {
        a: "如果市场对您不利，并导致保证金追加",
        b: "当我平仓获利时",
        c: "当有人建议您因为您在盈利交易而添加资金",
        d: "当有存款促销活动时",
      },
    },
    "4": {
      id: 4,
      question: "当您交易股票差价合约（CFD）时，以下哪种说法是正确的？",
      options: {
        a: "您有权参加公司的年度股东大会",
        b: "相比交易实物股票，您承担的风险较小",
        c: "您不直接以自己的名义拥有相关股票",
        d: "您拥有股票的表决权",
      },
    },
    "5": {
      id: 5,
      question: "以下哪种描述最准确地解释了“跳空”（Gapping）？",
      options: {
        a: "当市场价格在图表上出现尖锐上升或下降的情况下，价格之间出现断开，无交易发生",
        b: "进行大额存款或提款后，您账户余额的变化",
        c: "“跳空”是买价和卖价之间的差距",
        d: "“跳空”是市场开盘价和收盘价之间的差距",
      },
    },
    "6": {
      id: 6,
      question: "谁最终负责监控和管理您的交易账户？",
      options: {
        a: "公司的风险团队",
        b: "我有责任监控和管理我的交易账户",
        c: "我的交易账户不需要管理或监控",
        d: "我的财务顾问应该监控我的交易账户",
      },
    },
    "7": {
      id: 7,
      question: "以下哪个不是主要货币对？",
      options: {
        a: "USDJPY",
        b: "NZDJPY",
        c: "EURUSD",
        d: "USDGBP",
      },
    },
    "8": {
      id: 8,
      question: "什么是市场波动性？",
      options: {
        a: "当价格在一段时间内几乎没有变动或没有变动时",
        b: "当价格在短时间内大幅波动时",
        c: "波动性是衡量您在交易中将获得多少利润的指标",
        d: "当市场崩盘时",
      },
    },
    "9": {
      id: 9,
      question: "用借入的资本进行交易的术语是什么？",
      options: {
        a: "保证金交易",
        b: "盈利交易",
        c: "期权交易",
        d: "股票交易",
      },
    },
    "10": {
      id: 10,
      question: "止损订单（Stop Loss）的作用是什么？",
      options: {
        a: "保证您不会亏钱",
        b: "如果触发止损订单，会给您支付奖金",
        c: "通过以下一个可用价格进入交易来限制您的亏损",
        d: "它会退还您所有的损失",
      },
    },
    "11": {
      id: 11,
      question: "交易杠杆产品的风险包括以下哪些？",
      options: {
        a: "您可能会亏损超过您在账户中存入的资金",
        b: "由于杠杆元素，您可能遭受通常与更大投资相关的损失",
        c: "高杠杆交易可能因为标的资产的价值或水平发生相对较小的变动而出现显著的价值变化",
        d: "所有上述情况",
      },
    },
    "12": {
      id: 12,
      question:
        "您决定以0.74000的汇率买入价值为A$10,000的AUDUSD。几周后，卖出价为0.72000。如果您以0.72000的价格平仓，您将在美元方面获利/亏损多少（不计任何费用或佣金支付）？",
      options: {
        a: "亏损$200",
        b: "获利$200",
        c: "获利$2,000",
        d: "亏损$2,000",
      },
    },
    "13": {
      id: 13,
      question: "以下哪种情况会要求您充值您的账户？",
      options: {
        a: "当您获利时",
        b: "市场对您不利，您接近自动平仓，但您希望保持持仓",
        c: "您的持仓已被平仓，且您不想再开仓",
        d: "当您希望关闭账户并支付所有未结费用时",
      },
    },
    "14": {
      id: 14,
      question: "对于买入（做多）交易，您会在哪里设置止损订单（SL）？",
      options: {
        a: "在您的入场价格上方",
        b: "在您的入场价格下方",
        c: "与止盈价格相同",
        d: "只能在卖出（做空）交易上设置止损订单",
      },
    },
    "15": {
      id: 15,
      question: "在使用保证金交易股票差价合约（CFD）时，以下哪种说法是正确的？",
      options: {
        a: "您将拥有所交易公司的一部分股权",
        b: "您的利润和损失将相对于保证金要求而放大",
        c: "CFD交易将使您有权参与股东投票",
        d: "您将始终从CFD做市商那里获得股息",
      },
    },
    "16": {
      id: 16,
      question:
        "您持有2盎司的黄金多头（买入）持仓，市场中间价目前为1,800美元。如果此交易的保证金要求为5%，那么此交易的总保证金要求是多少？",
      options: {
        a: "90美元",
        b: "180美元",
        c: "900美元",
        d: "36美元",
      },
    },
    "17": {
      id: 17,
      question:
        "原油合约的一手合约规模为1000桶。当前价格为每桶50美元，您决定购买五手合约，总价值为250,000美元（50美元 x 5000桶）。后来，原油价格下跌至每桶45美元，您决定平仓。您的盈利/亏损金额是多少（不计任何费用或佣金支付）？",
      options: {
        a: "盈利50,000美元",
        b: "亏损25,000美元",
        c: "亏损50,000美元",
        d: "盈利25,000美元",
      },
    },
    "18": {
      id: 18,
      question: "5:1的风险-收益比是什么意思？",
      options: {
        a: "如果您冒险250美元，您可能会损失1,250美元",
        b: "如果您冒险350美元，您有机会赢得1,750美元",
        c: "如果您冒险25,000美元，您有机会赢得5,000美元",
        d: "如果您冒险1,000美元，您有机会赢得5,000美元",
      },
    },
    "19": {
      id: 19,
      question: "在交易杠杆产品时，以下哪种说法是正确的？",
      options: {
        a: "利润和损失都可能被放大",
        b: "只有利润可以被放大",
        c: "只有损失可以被放大",
        d: "没有任何放大效果",
      },
    },
    "20": {
      id: 20,
      question: "为什么会使用止损订单？",
      options: {
        a: "开立新的持仓",
        b: "节省交易费用",
        c: "限制您的交易亏损",
        d: "在交易中获取最大利润",
      },
    },
    "21": {
      id: 21,
      question:
        "您当前的账户余额为50,000美元，账户权益为40,000美元，仅有一个AUDUSD的持仓，保证金要求为30,000美元。保证金警戒水平为100%。在以下哪种情况下您会收到保证金警告？",
      options: {
        a: "当您的账户余额低于40,000美元时",
        b: "当您的账户余额低于50,000美元时",
        c: "当您的账户权益低于30,000美元时",
        d: "当您的账户权益为0时",
      },
    },
    "22": {
      id: 22,
      question:
        "AUDUSD合约的保证金要求为4%。您以名义价值为A$100,000的AUDUSD开立一个持仓。此交易的保证金要求是多少？",
      options: {
        a: "A$4,000",
        b: "A$40,000",
        c: "A$400",
        d: "A$40",
      },
    },
    "23": {
      id: 23,
      question:
        "您持有3盎司的澳元现货黄金多头（买入）持仓，市场中间价目前为A$2,200。如果此交易的保证金要求为5%，那么此交易的总保证金要求是多少？",
      options: {
        a: "A$2,200",
        b: "A$330",
        c: "A$3,300",
        d: "A$220",
      },
    },
  },
  "vi-vn": {
    "1": {
      id: 1,
      question: "Thuật ngữ 'equity' đề cập đến điều gì?",
      options: {
        a: "Equity là số dư tài khoản cộng với lợi nhuận hoặc lỗ lãi nổi của tất cả các vị thế mở của bạn",
        b: "Equity là tổng số tiền ký quỹ hiện tại được sử dụng để duy trì tất cả các vị thế mở",
        c: "Equity là khoảng cách giữa giá mua và giá chào bán",
        d: "Equity là tổng số ký quỹ yêu cầu để giữ tất cả các vị thế mở hiện tại",
      },
    },
    "2": {
      id: 2,
      question: "Khái niệm 'spread' có ý nghĩa gì?",
      options: {
        a: "Lợi nhuận từ một giao dịch",
        b: "Thua lỗ từ một giao dịch",
        c: "Sự khác biệt giữa giá chào mua và giá chào bán",
        d: "Sự khác biệt giữa giá mở cửa và giá đóng cửa thị trường",
      },
    },
    "3": {
      id: 3,
      question: "Khi nào bạn có thể được yêu cầu nạp tiền vào tài khoản?",
      options: {
        a: "Nếu thị trường di chuyển ngược lại bạn và dẫn đến cuộc gọi ký quỹ",
        b: "Khi bạn đã đóng vị thế của mình với lợi nhuận",
        c: "Khi có người đề xuất nạp tiền vì bạn đang giao dịch có lợi",
        d: "Khi có khuyến mãi nạp tiền ký quỹ",
      },
    },
    "4": {
      id: 4,
      question: "Khi giao dịch hợp đồng chênh lệch giá cổ phiếu, câu nào đúng?",
      options: {
        a: "Bạn có quyền tham dự cuộc họp đại hội cổ đông hàng năm",
        b: "Bạn gánh chịu ít rủi ro hơn so với giao dịch cổ phiếu vật lý",
        c: "Bạn không sở hữu trực tiếp cổ phiếu cơ bản trong tên của bạn",
        d: "Bạn có quyền bỏ phiếu về cổ phiếu",
      },
    },
    "5": {
      id: 5,
      question: "Trong số các câu sau, cái nào mô tả 'gapping' tốt nhất?",
      options: {
        a: "Gapping là khoảng cách giữa các mức giá trên biểu đồ xảy ra khi giá thị trường tạo động lực mạnh lên hoặc xuống mà không có giao dịch xảy ra giữa các mức giá đó",
        b: "Thay đổi trong số dư tài khoản của bạn sau khi thực hiện một khoản nạp tiền hoặc rút tiền lớn",
        c: " 'Gap' là sự khác biệt giữa giá mua và giá bán",
        d: " 'Gap' là sự khác biệt giữa giá mở cửa và giá đóng cửa thị trường",
      },
    },
    "6": {
      id: 6,
      question:
        "Ai chịu trách nhiệm cuối cùng trong việc giám sát và quản lý tài khoản giao dịch của bạn?",
      options: {
        a: "Nhóm rủi ro của Công ty",
        b: "Đó là trách nhiệm của tôi để giám sát và quản lý tài khoản giao dịch của mình",
        c: "Tài khoản giao dịch của tôi không đòi hỏi quản lý hoặc giám sát",
        d: "Cố vấn tài chính của tôi nên giám sát tài khoản giao dịch của tôi",
      },
    },
    "7": {
      id: 7,
      question: "Cặp tiền tệ nào không thuộc nhóm cặp tiền tệ chính?",
      options: {
        a: "USDJPY",
        b: "NZDJPY",
        c: "EURUSD",
        d: "USDGBP",
      },
    },
    "8": {
      id: 8,
      question: "Độ biến động thị trường là gì?",
      options: {
        a: "Khi không có hoặc rất ít sự di chuyển trong giá trong một khoảng thời gian",
        b: "Khi có sự di chuyển lớn trong giá trong một khoảng thời gian ngắn",
        c: "Biến động thị trường là một chỉ số đo lường lợi nhuận bạn sẽ đạt được trong một giao dịch",
        d: "Khi thị trường sụp đổ",
      },
    },
    "9": {
      id: 9,
      question:
        "Thuật ngữ nào được sử dụng để mô tả việc giao dịch với vốn vay?",
      options: {
        a: "Giao dịch ký quỹ",
        b: "Giao dịch có lợi nhuận",
        c: "Giao dịch tùy chọn",
        d: "Giao dịch cổ phiếu",
      },
    },
    "10": {
      id: 10,
      question: "Giới hạn lỗ (stop loss) có chức năng gì?",
      options: {
        a: "Đảm bảo bạn sẽ không mất tiền",
        b: "Trả cho bạn một khoản thưởng nếu được kích hoạt",
        c: "Giới hạn lỗ bằng cách thực hiện giao dịch với giá tiếp theo có sẵn",
        d: "Hoàn lại tất cả các khoản lỗ của bạn",
      },
    },
    "11": {
      id: 11,
      question: "Các rủi ro của việc giao dịch các sản phẩm ký quỹ là gì?",
      options: {
        a: "Bạn có thể mất tiền nhiều hơn số tiền bạn đã gửi vào tài khoản",
        b: "Do yếu tố đòn bẩy, bạn có thể gánh chịu những khoản lỗ thường xuyên liên quan đến một khoản đầu tư lớn hơn rất nhiều",
        c: "Các giao dịch sử dụng đòn bẩy cao có thể chịu sự biến đổi đáng kể về giá trị do sự thay đổi tương đối nhỏ về giá trị hoặc mức độ của tài sản cơ bản",
        d: "Tất cả các lựa chọn trên",
      },
    },
    "12": {
      id: 12,
      question:
        "Bạn quyết định mua 10.000 AUDUSD với giá 0.74000. Một vài tuần sau, giá bán đứng ở mức 0.72000. Nếu bạn đóng vị thế tại 0.72000, bạn sẽ có lãi/lỗ bao nhiêu USD (không tính bất kỳ khoản phí hoặc chi trả hoa hồng nào)?",
      options: {
        a: "Lỗ 200 USD",
        b: "Lãi 200 USD",
        c: "Lãi 2.000 USD",
        d: "Lỗ 2.000 USD",
      },
    },
    "13": {
      id: 13,
      question: "Trường hợp nào sau đây sẽ yêu cầu bạn nạp tiền vào tài khoản?",
      options: {
        a: "Khi bạn có lợi nhuận",
        b: "Thị trường di chuyển ngược lại bạn và bạn gần đạt đến điều kiện tự động đóng vị thế, nhưng bạn muốn giữ các vị thế mở",
        c: "Các vị thế của bạn đã được đóng và bạn không muốn mở thêm",
        d: "Khi bạn muốn đóng tài khoản và thanh toán tất cả các khoản phí còn lại của bạn",
      },
    },
    "14": {
      id: 14,
      question:
        "Bạn sẽ đặt giới hạn lỗ (SL) ở đâu cho một giao dịch mua (dài)?",
      options: {
        a: "Trên giá mua",
        b: "Dưới giá mua",
        c: "Ở cùng mức giá với điểm chốt lời",
        d: "Bạn chỉ có thể đặt giới hạn lỗ trên một giao dịch bán (ngắn)",
      },
    },
    "15": {
      id: 15,
      question:
        "Khi giao dịch hợp đồng chênh lệch giá cổ phiếu bằng ký quỹ, câu nào sau đây là đúng?",
      options: {
        a: "Bạn sẽ sở hữu một phần trăm cổ phiếu của công ty mà bạn đã giao dịch",
        b: "Lợi nhuận và lỗ lãi của bạn sẽ được tăng gấp đôi so với yêu cầu ký quỹ",
        c: "Giao dịch HĐCG sẽ cho phép bạn tham gia bỏ phiếu của cổ đông",
        d: "Bạn luôn nhận được cổ tức từ nhà làm thị trường HĐCG của bạn",
      },
    },
    "16": {
      id: 16,
      question:
        "Bạn giữ vị thế mua vàng 2 ounce và giá trung bình thị trường hiện tại là 1.800 USD. Nếu yêu cầu ký quỹ cho giao dịch này là 5%, tổng yêu cầu ký quỹ cho giao dịch này là bao nhiêu?",
      options: {
        a: "90 USD",
        b: "180 USD",
        c: "900 USD",
        d: "36 USD",
      },
    },
    "17": {
      id: 17,
      question:
        "Kích thước một hợp đồng dầu thô là 1000 thùng. Hiện tại, giá dầu thô đang giao dịch ở mức 50 USD mỗi thùng, và bạn quyết định mua năm hợp đồng với giá 250.000 USD (50 USD x 5000 thùng). Sau đó, giá dầu thô giảm xuống 45 USD mỗi thùng, và bạn quyết định đóng vị thế. Bạn đã có lãi/lỗ bao nhiêu (không tính bất kỳ khoản phí hoặc chi trả hoa hồng nào)?",
      options: {
        a: "Lãi 50.000 USD",
        b: "Lỗ 25.000 USD",
        c: "Lỗ 50.000 USD",
        d: "Lãi 25.000 USD",
      },
    },
    "18": {
      id: 18,
      question: "5:1 là tỷ lệ phần thưởng-so với-rủi ro có ý nghĩa gì?",
      options: {
        a: "Nếu bạn rủi ro 250 USD, bạn có thể mất 1.250 USD",
        b: "Nếu bạn rủi ro 350 USD, bạn có cơ hội giành được 1.750 USD",
        c: "Bạn có cơ hội giành được 5.000 USD nếu bạn rủi ro 25.000 USD",
        d: "Bạn có cơ hội giành được 5.000 USD nếu bạn rủi ro 1.000 USD",
      },
    },
    "19": {
      id: 19,
      question: "Khi giao dịch các sản phẩm ký quỹ, câu nào sau đây là đúng?",
      options: {
        a: "Cả lợi nhuận và lỗ lãi đều có thể tăng gấp đôi",
        b: "Chỉ lợi nhuận có thể tăng gấp đôi",
        c: "Chỉ lỗ lãi có thể tăng gấp đôi",
        d: "Không có gì được tăng gấp đôi",
      },
    },
    "20": {
      id: 20,
      question: "Tại sao bạn sử dụng giới hạn lỗ (stop loss)?",
      options: {
        a: "Để mở vị thế mới",
        b: "Để tiết kiệm phí giao dịch",
        c: "Để giới hạn lỗ trong một giao dịch",
        d: "Để đạt lợi nhuận tối đa trong một giao dịch",
      },
    },
    "21": {
      id: 21,
      question:
        "Bạn có số dư tài khoản hiện tại là 50.000 USD, tổng vốn là 40.000 USD và chỉ có một vị thế mở duy nhất trong cặp tiền tệ AUDUSD với yêu cầu ký quỹ là 30.000 USD. Mức cuộc gọi ký quỹ là 100%. Theo điều kiện nào sau đây bạn sẽ nhận được thông báo cuộc gọi ký quỹ?",
      options: {
        a: "Khi số dư tài khoản thấp hơn 40.000 USD",
        b: "Khi số dư tài khoản thấp hơn 50.000 USD",
        c: "Khi tổng vốn tài khoản thấp hơn 30.000 USD",
        d: "Khi tổng vốn tài khoản là 0",
      },
    },
    "22": {
      id: 22,
      question:
        "Yêu cầu ký quỹ cho hợp đồng AUDUSD là 4%. Bạn đã mở vị thế với giá trị giao dịch giả định là 100.000 AUD. Yêu cầu ký quỹ là bao nhiêu?",
      options: {
        a: "4.000 AUD",
        b: "40.000 AUD",
        c: "400 AUD",
        d: "40 AUD",
      },
    },
    "23": {
      id: 23,
      question:
        "Bạn giữ vị thế mua vàng theo giá vàng tức thì của Úc 3 ounce, và giá trung bình thị trường hiện tại là 2.200 AUD. Nếu yêu cầu ký quỹ cho giao dịch này là 5%, tổng yêu cầu ký quỹ cho giao dịch này là bao nhiêu?",
      options: {
        a: "2.200 AUD",
        b: "330 AUD",
        c: "3.300 AUD",
        d: "220 AUD",
      },
    },
  },
  "th-th": {
    "1": {
      id: 1,
      question: "คำว่า 'equity' หมายถึงอะไร?",
      options: {
        a: "Equity คือยอดเงินในบัญชีของคุณรวมกับกำไรหรือขาดทุนที่เกิดขึ้นจากตำแหน่งที่เปิดอยู่ทั้งหมด",
        b: "Equity คือจำนวนรวมของมาร์จินที่ใช้อยู่เพื่อรักษาตำแหน่งที่เปิดอยู่ทั้งหมด",
        c: "Equity คือความแตกต่างระหว่างราคาเสนอซื้อและราคาเสนอขาย",
        d: "Equity คือมาร์จินรวมทั้งหมดที่จำเป็นต้องใช้เพื่อเก็บตำแหน่งที่เปิดอยู่ในปัจจุบัน",
      },
    },
    "2": {
      id: 2,
      question: "คำว่า 'spread' หมายถึงอะไร?",
      options: {
        a: "กำไรจากการเทรด",
        b: "ขาดทุนจากการเทรด",
        c: "ความแตกต่างระหว่างราคาเสนอซื้อและราคาเสนอขาย",
        d: "ความแตกต่างระหว่างราคาเปิดตลาดและราคาปิดตลาด",
      },
    },
    "3": {
      id: 3,
      question: "เมื่อคุณมีความเป็นไปได้ที่จะถูกขอเพิ่มเงินในบัญชีคือเมื่อใด?",
      options: {
        a: "ถ้าตลาดเคลื่อนที่เข้าในทิศทางตรงข้ามและทำให้เกิดการเรียกคืนมาร์จิน",
        b: "เมื่อฉันปิดตำแหน่งที่กำไร",
        c: "เมื่อมีคนแนะนำให้เพิ่มเงินเนื่องจากคุณทำการเทรดได้ผลกำไร",
        d: "เมื่อมีโปรโมชั่นฝากมาร์จิน",
      },
    },
    "4": {
      id: 4,
      question: "เมื่อคุณเทรด CFD หุ้น คำใดถูกต้อง?",
      options: {
        a: "คุณมีสิทธิ์เข้าร่วมการประชุมสุดยอดประจำปี",
        b: "คุณเป็นผู้รับผิดชอบน้อยกว่าการเทรดหุ้นจริง",
        c: "คุณไม่เป็นเจ้าของหุ้นในตลาดหลักทรัพย์ตรงๆในชื่อของคุณ",
        d: "คุณมีสิทธิ์ลงคะแนนเลือกตั้งหุ้น",
      },
    },
    "5": {
      id: 5,
      question: "คำอธิบายใดเกี่ยวกับ 'gapping' ที่ถูกต้องที่สุด?",
      options: {
        a: "การเกิดช่องว่างระหว่างราคาในแผนภูมิเมื่อราคาของตลาดเคลื่อนที่ขึ้นหรือลงอย่างรวดเร็วโดยไม่มีการซื้อขายที่เกิดขึ้นระหว่างราคาเหล่านั้น",
        b: "การเปลี่ยนแปลงในยอดเงินในบัญชีของคุณหลังจากฝากหรือถอนจำนวนมาก",
        c: "ความต่างระหว่างราคาเสนอซื้อและราคาเสนอขาย",
        d: "ความต่างระหว่างราคาเปิดตลาดและราคาปิดตลาด",
      },
    },
    "6": {
      id: 6,
      question:
        "ใครคือผู้รับผิดชอบสุดท้ายในการตรวจสอบและจัดการบัญชีการเทรดของคุณ?",
      options: {
        a: "ทีมความเสี่ยงของบริษัท",
        b: "มันเป็นความรับผิดชอบของฉันที่จะตรวจสอบและจัดการบัญชีการเทรดของฉัน",
        c: "บัญชีการเทรดของฉันไม่ต้องการการจัดการหรือการตรวจสอบ",
        d: "ที่ปรึกษาทางการเงินของฉันควรตรวจสอบบัญชีการเทรดของฉัน",
      },
    },
    "7": {
      id: 7,
      question: "คู่เงินที่ต่อเนื่องไม่ใช่คู่เงินหลักใด?",
      options: {
        a: "USDJPY",
        b: "NZDJPY",
        c: "EURUSD",
        d: "USDGBP",
      },
    },
    "8": {
      id: 8,
      question: "ความผันผวนในตลาดหมายถึงอะไร?",
      options: {
        a: "เมื่อไม่มีการเคลื่อนไหวในราคาเป็นเวลานานหรือไม่มีการเคลื่อนไหวเล็กน้อย",
        b: "เมื่อมีการเคลื่อนไหวของราคามากในช่วงเวลาสั้นๆ",
        c: "ความผันผวนเป็นตัววัดของกำไรที่คุณจะได้รับจากการเทรด",
        d: "เมื่อตลาดล่มสลาย",
      },
    },
    "9": {
      id: 9,
      question: "คำศัพท์ที่ใช้เพื่ออธิบายการเทรดด้วยทุนยืมคืออะไร?",
      options: {
        a: "Margin trading",
        b: "Profitable trading",
        c: "Options trading",
        d: "Share trading",
      },
    },
    "10": {
      id: 10,
      question: "หยุดการสูญเสีย (stop loss) ทำหน้าที่อะไร?",
      options: {
        a: "รับรองว่าคุณจะไม่สูญเสียเงิน",
        b: "จ่ายโบนัสให้คุณหากถูกเปิดใช้งาน",
        c: "จำกัดความเสียของคุณโดยเข้าสู่การเทรดที่ราคาที่พร้อมใช้งานถัดไป",
        d: "คืนเงินทุกส่วนของความเสียของคุณ",
      },
    },
    "11": {
      id: 11,
      question: "ความเสี่ยงของผลิตภัณฑ์ที่ใช้เลเวอเรจคืออะไร?",
      options: {
        a: "คุณอาจสูญเสียเงินมากกว่าจำนวนเงินที่คุณฝากในบัญชีของคุณ",
        b: "เนื่องจากเลเวอเรจคุณอาจเสี่ยงต่อการสูญเสียที่สัมพันธ์กับการลงทุนที่มีขนาดใหญ่มากขึ้น",
        c: "ธุรกรรมที่ใช้เลเวอเรจสูงมีผลกระทบใหญ่ต่อมูลค่าหรือระดับของสินทรัพย์ในพื้นฐานที่เปลี่ยนแปลงเล็กน้อย",
        d: "ทั้งหมดข้างต้น",
      },
    },
    "12": {
      id: 12,
      question:
        "คุณตัดสินใจซื้อ AUDUSD ที่มูลค่า 10,000 ดอลลาร์ออสเตรเลีย (A$) ที่ราคา 0.74000 เมื่อสองสัปดาห์ที่ผ่านมาราคาขายอยู่ที่ 0.72000 ถ้าคุณปิดตำแหน่งของคุณที่ 0.72000 คุณจะทำกำไร/ขาดทุนเท่าไรในหน่วยเงินดอลลาร์สหรัฐ (USD) (โดยไม่รวมค่าธรรมเนียมหรือค่าคอมมิชชั่นใดๆ)",
      options: {
        a: "ขาดทุน 200 ดอลลาร์",
        b: "กำไร 200 ดอลลาร์",
        c: "กำไร 2,000 ดอลลาร์",
        d: "ขาดทุน 2,000 ดอลลาร์",
      },
    },
    "13": {
      id: 13,
      question: "สถานการณ์ใดต่อไปนี้จำเป็นต้องเพิ่มเงินในบัญชีของคุณ?",
      options: {
        a: "เมื่อคุณทำกำไร",
        b: "ตลาดกำลังเคลื่อนไหวตรงข้ามกับคุณและคุณกำลังใกล้ถึงการปิดอัตโนมัติ แต่คุณต้องการเก็บตำแหน่งเปิดไว้",
        c: "ตำแหน่งของคุณถูกปิดแล้วและคุณไม่ต้องการเปิดอีกต่อไป",
        d: "เมื่อคุณต้องการปิดบัญชีของคุณและชำระค่าธรรมเนียมที่ค้างอยู่ทั้งหมด",
      },
    },
    "14": {
      id: 14,
      question: "คุณจะวางหยุดการสูญเสีย (SL) สำหรับการเทรดซื้อ (long) ที่ไหน?",
      options: {
        a: "เหนือราคาเริ่มต้น",
        b: "ใต้ราคาเริ่มต้น",
        c: "ในอัตราเดียวกับการบริหารกำไร",
        d: "คุณสามารถวางหยุดการสูญเสียได้เฉพาะในการเทรดขาย (short) เท่านั้น",
      },
    },
    "15": {
      id: 15,
      question: "เมื่อเทรด CFD หุ้นด้วยมาร์จิน คำใดถูกต้อง?",
      options: {
        a: "คุณจะเป็นเจ้าของส่วนหนึ่งของบริษัทที่คุณเทรด",
        b: "กำไรและขาดทุนของคุณจะขยายขนาดตามความต้องการของมาร์จิน",
        c: "การเทรด CFD จะให้คุณมีสิทธิ์ลงคะแนนเลือกตั้งในการประชุมผู้ถือหุ้น",
        d: "คุณจะได้รับเงินปันผลจากผู้ทำตลาด CFD ตลอดเวลา",
      },
    },
    "16": {
      id: 16,
      question:
        "คุณถือตำแหน่งซื้อ (long) ในทองคำ น้ำหนัก 2 ออนซ์ และราคาตลาดกลางอยู่ที่ 1,800 ดอลลาร์ หากมาร์จินที่จำเป็นสำหรับธุรกรรมนี้คือ 5% มูลค่ามาร์จินรวมสำหรับธุรกรรมนี้คือเท่าใด?",
      options: {
        a: "90 ดอลลาร์",
        b: "180 ดอลลาร์",
        c: "900 ดอลลาร์",
        d: "36 ดอลลาร์",
      },
    },
    "17": {
      id: 17,
      question:
        "ขนาดของสัญญาน้ำมันดิบคือ 1000 บาร์เรล ปัจจุบันกำลังเทรดอยู่ที่ราคา 50 ดอลลาร์ต่อบาร์เรล และคุณตัดสินใจซื้อสัญญาห้าสัญญาเป็นจำนวนเงิน 250,000 ดอลลาร์ (50 ดอลลาร์ x 5000 บาร์เรล) ภายหลังราคาน้ำมันดิบลดลงเป็น 45 ดอลลาร์ต่อบาร์เรล และคุณตัดสินใจปิดตำแหน่งของคุณ คุณได้ทำกำไร/ขาดทุนเท่าใด (โดยไม่รวมค่าธรรมเนียมหรือค่าคอมมิชชั่นใดๆ)",
      options: {
        a: "กำไร 50,000 ดอลลาร์",
        b: "ขาดทุน 25,000 ดอลลาร์",
        c: "ขาดทุน 50,000 ดอลลาร์",
        d: "กำไร 25,000 ดอลลาร์",
      },
    },
    "18": {
      id: 18,
      question: "อัตราส่วนรางวัลต่อความเสี่ยง 5:1 หมายความว่าอย่างไร?",
      options: {
        a: "หากคุณเสี่ยงทุน 250 ดอลลาร์ คุณสามารถขาดทุนได้ 1,250 ดอลลาร์",
        b: "หากคุณเสี่ยงทุน 350 ดอลลาร์ คุณมีโอกาสได้รับเงิน 1,750 ดอลลาร์",
        c: "คุณมีโอกาสได้รับ 5,000 ดอลลาร์ หากคุณเสี่ยงทุน 25,000 ดอลลาร์",
        d: "คุณมีโอกาสได้รับ 5,000 ดอลลาร์ หากคุณเสี่ยงทุน 1,000 ดอลลาร์",
      },
    },
    "19": {
      id: 19,
      question: "เมื่อเทรดผลิตภัณฑ์ที่ใช้เลเวอเรจ คำใดถูกต้อง?",
      options: {
        a: "ทั้งกำไรและขาดทุนสามารถขยายตัวได้",
        b: "เฉพาะกำไรเท่านั้นที่สามารถขยายตัวได้",
        c: "เฉพาะขาดทุนเท่านั้นที่สามารถขยายตัวได้",
        d: "ไม่มีสิ่งใดขยายตัว",
      },
    },
    "20": {
      id: 20,
      question: "ทำไมคุณต้องใช้หยุดการสูญเสีย?",
      options: {
        a: "เพื่อเปิดตำแหน่งใหม่",
        b: "เพื่อประหยัดค่าธรรมเนียมธุรกรรม",
        c: "เพื่อจำกัดความเสียของคุณในการเทรด",
        d: "เพื่อทำกำไรสูงสุดในการเทรด",
      },
    },
    "21": {
      id: 21,
      question:
        "คุณมียอดเงินในบัญชีปัจจุบันอยู่ที่ 50,000 ดอลลาร์ ส่วนความเสี่ยงในบัญชีอยู่ที่ 40,000 ดอลลาร์ และมีเพียงตำแหน่งเดียวเปิดใน AUDUSD โดยมีความต้องการมาร์จินที่ 30,000 ดอลลาร์ ระดับเรียกเก็บมาร์จินคือ 100% ภายใต้เงื่อนไขใดคุณจะได้รับการเรียกเก็บมารจิน?",
      options: {
        a: "เมื่อยอดเงินในบัญชีต่ำกว่า 40,000 ดอลลาร์",
        b: "เมื่อยอดเงินในบัญชีต่ำกว่า 50,000 ดอลลาร์",
        c: "เมื่อความเสี่ยงในบัญชีต่ำกว่า 30,000 ดอลลาร์",
        d: "เมื่อความเสี่ยงในบัญชีเป็น 0 ดอลลาร์",
      },
    },
    "22": {
      id: 22,
      question:
        "มาร์จินที่จำเป็นสำหรับสัญญา AUDUSD คือ 4% คุณเปิดตำแหน่งใน AUDUSD ที่มูลค่า 100,000 ดอลลาร์ มาร์จินที่จำเป็นคือเท่าใด?",
      options: {
        a: "4,000 ดอลลาร์",
        b: "40,000 ดอลลาร์",
        c: "400 ดอลลาร์",
        d: "40 ดอลลาร์",
      },
    },
    "23": {
      id: 23,
      question:
        "คุณถือตำแหน่งซื้อ (long) 3 ออนซ์ ในทองคำสปอตของดอลลาร์ออสเตรเลีย และราคาตลาดกลางอยู่ที่ A$2,200 หากมาร์จินที่จำเป็นสำหรับธุรกรรมนี้คือ 5% มูลค่ามาร์จินรวมสำหรับธุรกรรมนี้คือเท่าใด?",
      options: {
        a: "A$2,200",
        b: "A$330",
        c: "A$3,300",
        d: "A$220",
      },
    },
  },
  "jp-jp": {
    "1": {
      id: 1,
      question: "「株式」はどのような意味ですか？",
      options: {
        a: "株式は、口座残高に加えて、すべてのオープンポジションの浮動利益（または損失）の合計です。",
        b: "株式は、現在のすべてのオープンポジションを維持するために使用されているマージンの総額です。",
        c: "株式は、売値と買値の差です。",
        d: "株式は、すべての現在のオープンポジションを保持するために必要な総マージンです。",
      },
    },
    "2": {
      id: 2,
      question: "「スプレッド」とは何ですか？",
      options: {
        a: "トレードの利益",
        b: "トレードの損失",
        c: "売値と買値の差",
        d: "市場の開始価格と終了価格の差",
      },
    },
    "3": {
      id: 3,
      question: "口座に資金を追加するよう求められるのはいつですか？",
      options: {
        a: "市場が逆に動いてマージンコールになる場合",
        b: "利益を上げた後にポジションを閉じた場合",
        c: "誰かが利益を上げているために資金を追加することを提案した場合",
        d: "マージンを入金するためのプロモーションがある場合",
      },
    },
    "4": {
      id: 4,
      question: "株式CFDを取引する場合、以下の記述のうちどれが正しいですか？",
      options: {
        a: "株主総会に出席する権利があります。",
        b: "物理株式取引よりもリスクが少ないです。",
        c: "直接に株式を所有していません。",
        d: "株式の議決権を持っています。",
      },
    },
    "5": {
      id: 5,
      question:
        "次のうち、「ギャッピング」を最もよく説明するものはどれですか？",
      options: {
        a: "ギャッピングとは、市場価格が急激に上昇または下降し、その間に取引が行われない場合にチャート上で価格の差が生じることです。",
        b: "大口預金または出金後の口座残高の変化です。",
        c: "「ギャップ」とは、買値と売値の差です。",
        d: "「ギャップ」とは、市場の開始価格と終了価格の差です。",
      },
    },
    "6": {
      id: 6,
      question: "トレードアカウントの監視と管理は最終的に誰の責任ですか？",
      options: {
        a: "企業のリスクチーム",
        b: "トレードアカウントの監視と管理は私の責任です",
        c: "私のトレードアカウントは管理や監視を必要としません",
        d: "私のファイナンシャルアドバイザーが私のトレードアカウントを監視すべきです",
      },
    },
    "7": {
      id: 7,
      question: "以下のうち、メジャーな通貨ペアではないものはどれですか？",
      options: {
        a: "USDJPY",
        b: "NZDJPY",
        c: "EURUSD",
        d: "USDGBP",
      },
    },
    "8": {
      id: 8,
      question: "市場のボラティリティとは何ですか？",
      options: {
        a: "一定期間内に価格がほとんど変動しないまたは変動しないとき",
        b: "短期間で価格が大きく変動するとき",
        c: "ボラティリティはトレードで得られる利益の量の尺度です",
        d: "市場が暴落するとき",
      },
    },
    "9": {
      id: 9,
      question: "借り入れた資本を使って取引することを表す用語は何ですか？",
      options: {
        a: "マージントレード",
        b: "利益を上げるトレード",
        c: "オプショントレード",
        d: "株式取引",
      },
    },
    "10": {
      id: 10,
      question: "ストップロスはどのような役割を果たしますか？",
      options: {
        a: "損失を保証します",
        b: "活性化された場合にボーナスが支払われます",
        c: "次に利用可能な価格でトレードに参入し、損失を制限します",
        d: "すべての損失を払い戻します",
      },
    },
    "11": {
      id: 11,
      question: "レバレッジ取引のリスクは何ですか？",
      options: {
        a: "口座に入金した金額以上の損失を被る可能性があります",
        b: "レバレッジの要素により、通常よりも大きな投資に伴う損失を被る可能性があります",
        c: "強くレバレッジされた取引は、基礎となる資産の価値またはレベルのわずかな変化によって価値が大きく変動する可能性があります",
        d: "上記のすべて",
      },
    },
    "12": {
      id: 12,
      question:
        "AUDUSDを0.74000で10,000ドルの価値で購入することを決めます。数週間後、売値が0.72000になります。0.72000でポジションを閉じた場合、USDでの利益/損失はいくらになりますか（手数料や手数料の支払いは含まれません）？",
      options: {
        a: "$200の損失",
        b: "$200の利益",
        c: "$2,000の利益",
        d: "$2,000の損失",
      },
    },
    "13": {
      id: 13,
      question:
        "以下の状況のうち、アカウントに資金を追加する必要があるのはどれですか？",
      options: {
        a: "利益を上げている場合",
        b: "市場が逆に動いており、オートクローズに近づいているが、ポジションを保持したい場合",
        c: "ポジションがクローズされ、それ以上開かない場合",
        d: "アカウントを閉じてすべての未払い手数料をカバーしたい場合",
      },
    },
    "14": {
      id: 14,
      question:
        "買い（ロング）トレードのストップロス（SL）はどこに配置しますか？",
      options: {
        a: "エントリー価格より上に",
        b: "エントリー価格より下に",
        c: "テイクプロフィットと同じレートで",
        d: "売り（ショート）トレードにのみストップロスを配置できます",
      },
    },
    "15": {
      id: 15,
      question:
        "マージンでCFD株式を取引する場合、以下の記述のうち正しいものはどれですか？",
      options: {
        a: "取引した企業の一部を所有します",
        b: "利益と損失はマージン要件に対して拡大されます",
        c: "CFD取引により株主投票に参加できます",
        d: "CFDマーケットメーカーからは常に配当を受け取ります",
      },
    },
    "16": {
      id: 16,
      question:
        "金の2オンスのロング（買い）ポジションを保有しており、市場の中間価格は現在1800ドルです。この取引のマージン要件は5％です。この取引の合計マージン要件はいくらですか？",
      options: {
        a: "$90",
        b: "$180",
        c: "$900",
        d: "$36",
      },
    },
    "17": {
      id: 17,
      question:
        "原油の1契約のサイズは1000バレルです。現在の価格は1バレル当たり50ドルで、5つの契約を25万ドル（50ドル×5000バレル）で購入することを決めました。後で原油の価格が1バレル当たり45ドルに下がり、ポジションをクローズすることを決めました。利益/損失はいくらですか（手数料や手数料の支払いは無視します）？",
      options: {
        a: "$50,000の利益",
        b: "$25,000の損失",
        c: "$50,000の損失",
        d: "$25,000の利益",
      },
    },
    "18": {
      id: 18,
      question: "5：1のリワード対リスク比とはどういう意味ですか？",
      options: {
        a: "リスクが$250の場合、$1,250を失う可能性があります",
        b: "リスクが$350の場合、$1,750の勝利のチャンスがあります",
        c: "$25,000をリスクに対して$5,000の勝利のチャンスがあります",
        d: "$1,000をリスクに対して$5,000の勝利のチャンスがあります",
      },
    },
    "19": {
      id: 19,
      question: "レバレッジ取引をする際、どの記述が正しいですか？",
      options: {
        a: "利益と損失の両方が拡大される可能性があります",
        b: "利益のみが拡大される可能性があります",
        c: "損失のみが拡大される可能性があります",
        d: "何も拡大されません",
      },
    },
    "20": {
      id: 20,
      question: "ストップロスを使用する理由は何ですか？",
      options: {
        a: "新しいポジションを開くため",
        b: "取引手数料を節約するため",
        c: "トレードの損失を制限するため",
        d: "トレードで最大の利益を得るため",
      },
    },
    "21": {
      id: 21,
      question:
        "現在の口座残高は$50,000、口座資本は$40,000で、AUDUSDの1つのオープンポジションのマージン要件は$30,000です。マージンコールレベルは100％です。以下のどの状況でマージンコールを受け取りますか？",
      options: {
        a: "口座残高が$40,000未満の場合",
        b: "口座残高が$50,000未満の場合",
        c: "口座資本が$30,000未満の場合",
        d: "口座資本が$0の場合",
      },
    },
    "22": {
      id: 22,
      question:
        "AUDUSD契約のマージン要件は4％です。A$100,000の名義価値でAUDUSDのポジションを開きました。マージン要件はいくらですか？",
      options: {
        a: "A$4,000",
        b: "A$40,000",
        c: "A$400",
        d: "A$40",
      },
    },
    "23": {
      id: 23,
      question:
        "Australian dollar spot goldの3オンスのロング（買い）ポジションを保有しており、市場の中間価格は現在A$2,200です。この取引のマージン要件は5％です。この取引の合計マージン要件はいくらですか？",
      options: {
        a: "A$2,200",
        b: "A$330",
        c: "A$3,300",
        d: "A$220",
      },
    },
  },
  "id-id": {
    "1": {
      id: 1,
      question: "Apa yang dimaksud dengan 'ekuitas'?",
      options: {
        a: "Ekuitas adalah saldo akun Anda ditambah keuntungan atau kerugian mengambang dari semua posisi terbuka Anda",
        b: "Ekuitas adalah jumlah TOTAL margin yang saat ini digunakan untuk menjaga semua posisi terbuka",
        c: "Ekuitas adalah selisih antara harga bid dan ask",
        d: "Ekuitas adalah total margin yang diperlukan untuk menahan semua posisi terbuka saat ini",
      },
    },
    "2": {
      id: 2,
      question: "Apa itu 'spread'?",
      options: {
        a: "Keuntungan dari suatu perdagangan",
        b: "Kerugian dari suatu perdagangan",
        c: "Selisih antara harga bid dan ask",
        d: "Selisih antara harga pembukaan pasar dan harga penutupan pasar",
      },
    },
    "3": {
      id: 3,
      question:
        "Kapan kemungkinan Anda diminta untuk menambah dana ke akun Anda?",
      options: {
        a: "Jika pasar bergerak melawan Anda, dan menyebabkan panggilan margin",
        b: "Ketika Anda telah menutup posisi Anda untuk mendapatkan keuntungan",
        c: "Ketika seseorang menyarankan untuk menambah dana karena Anda berdagang dengan menguntungkan",
        d: "Ketika ada promosi untuk deposit margin",
      },
    },
    "4": {
      id: 4,
      question: "Ketika Anda berdagang CFD saham, pernyataan mana yang benar?",
      options: {
        a: "Anda berhak menghadiri rapat umum tahunan",
        b: "Anda mengambil risiko lebih rendah daripada berdagang saham fisik",
        c: "Anda tidak memiliki saham yang mendasari langsung atas nama Anda",
        d: "Anda memiliki hak suara saham",
      },
    },
    "5": {
      id: 5,
      question:
        "Yang mana dari berikut ini yang paling baik menggambarkan 'gapping'?",
      options: {
        a: "Gapping adalah jeda antara harga pada grafik yang terjadi ketika harga pasar membuat pergerakan tajam naik atau turun tanpa adanya perdagangan antara harga tersebut",
        b: "Perubahan saldo akun Anda setelah melakukan deposit atau penarikan besar",
        c: "‘Gap’ adalah perbedaan antara harga beli dan harga jual",
        d: "‘Gap’ adalah perbedaan antara harga pembukaan pasar dan harga penutupan pasar",
      },
    },
    "6": {
      id: 6,
      question:
        "Siapa yang bertanggung jawab secara penuh atas pemantauan dan pengelolaan akun perdagangan Anda?",
      options: {
        a: "Tim risiko Perusahaan",
        b: "Saya bertanggung jawab untuk memantau dan mengelola akun perdagangan saya sendiri",
        c: "Akun perdagangan saya tidak memerlukan pengelolaan atau pemantauan",
        d: "Penasihat keuangan saya harus memantau akun perdagangan saya",
      },
    },
    "7": {
      id: 7,
      question: "Manakah dari berikut ini BUKAN pasangan mata uang utama?",
      options: {
        a: "USDJPY",
        b: "NZDJPY",
        c: "EURUSD",
        d: "USDGBP",
      },
    },
    "8": {
      id: 8,
      question: "Apa itu volatilitas pasar?",
      options: {
        a: "Ketika ada sedikit atau tidak ada pergerakan harga selama periode waktu tertentu",
        b: "Ketika terjadi pergerakan harga yang besar dalam waktu singkat",
        c: "Volatilitas adalah ukuran seberapa besar keuntungan yang akan Anda dapatkan dari perdagangan",
        d: "Ketika pasar mengalami kehancuran",
      },
    },
    "9": {
      id: 9,
      question:
        "Apa istilah yang digunakan untuk menggambarkan perdagangan dengan modal pinjaman?",
      options: {
        a: "Margin trading",
        b: "Perdagangan menguntungkan",
        c: "Perdagangan opsi",
        d: "Perdagangan saham",
      },
    },
    "10": {
      id: 10,
      question: "Apa fungsi stop loss?",
      options: {
        a: "Menjamin Anda tidak akan kehilangan uang",
        b: "Membayarkan bonus jika diaktifkan",
        c: "Membatasi kerugian Anda dengan masuk ke dalam perdagangan pada harga tersedia berikutnya",
        d: "Mengembalikan semua kerugian Anda",
      },
    },
    "11": {
      id: 11,
      question: "Apa risiko dari produk ber-leverage?",
      options: {
        a: "Anda berpotensi kehilangan lebih banyak uang daripada yang Anda depositkan di akun Anda",
        b: "Karena elemen leverage, Anda dapat mengalami kerugian yang biasanya terkait dengan investasi yang jauh lebih besar",
        c: "Transaksi yang sangat ber-leverage rentan terhadap perubahan nilai yang signifikan akibat perubahan yang relatif kecil dalam nilai atau tingkat aset yang mendasari",
        d: "Semuanya benar",
      },
    },
    "12": {
      id: 12,
      question:
        "Anda memutuskan untuk membeli AUDUSD sebesar A$10,000 pada 0.74000. Beberapa minggu kemudian, harga jual berada di 0.72000. Berapa keuntungan/kerugian yang akan Anda dapatkan dalam USD (tidak termasuk biaya atau pembayaran komisi apa pun) jika Anda menutup posisi pada 0.72000?",
      options: {
        a: "Kerugian $200",
        b: "Keuntungan $200",
        c: "Keuntungan $2,000",
        d: "Kerugian $2,000",
      },
    },
    "13": {
      id: 13,
      question: "Dalam situasi berikut, kapan Anda perlu mendanai akun Anda?",
      options: {
        a: "Ketika Anda sedang menghasilkan keuntungan",
        b: "Pasar bergerak melawan Anda, dan Anda mendekati penutupan otomatis, tetapi Anda ingin tetap membuka posisi Anda",
        c: "Posisi Anda telah ditutup, dan Anda tidak ingin membuka lebih banyak lagi",
        d: "Ketika Anda ingin menutup akun Anda dan menutup semua biaya yang belum terbayar",
      },
    },
    "14": {
      id: 14,
      question:
        "Di mana Anda akan menempatkan stop loss (SL) untuk perdagangan beli (long)?",
      options: {
        a: "Di atas harga masuk Anda",
        b: "Di bawah harga masuk Anda",
        c: "Pada tingkat yang sama dengan take profit",
        d: "Anda hanya dapat menempatkan stop loss pada perdagangan jual (short)",
      },
    },
    "15": {
      id: 15,
      question:
        "Ketika berdagang saham CFD dengan margin, pernyataan berikut yang mana yang benar?",
      options: {
        a: "Anda akan memiliki persentase dari perusahaan yang Anda tempatkan perdagangan",
        b: "Keuntungan dan kerugian Anda akan diperbesar relatif terhadap persyaratan margin",
        c: "Perdagangan CFD akan memberi Anda hak untuk ikut serta dalam pemungutan suara pemegang saham",
        d: "Anda akan selalu menerima dividen dari pembuat pasar CFD Anda",
      },
    },
    "16": {
      id: 16,
      question:
        "Anda memegang posisi long (beli) 2 ons emas, dan harga tengah pasar saat ini adalah $1,800. Jika persyaratan margin untuk perdagangan ini adalah 5%, berapa total persyaratan margin untuk perdagangan ini?",
      options: {
        a: "$90",
        b: "$180",
        c: "$900",
        d: "$36",
      },
    },
    "17": {
      id: 17,
      question:
        "Ukuran satu kontrak minyak mentah adalah 1000 barel. Saat ini sedang diperdagangkan pada harga $50 per barel, dan Anda memutuskan untuk membeli lima kontrak senilai $250,000 ($50 x 5000 barel). Nanti, harga minyak mentah turun menjadi $45 per barel, dan Anda memutuskan untuk menutup posisi Anda. Berapa keuntungan/kerugian yang Anda peroleh (tidak termasuk biaya atau pembayaran komisi apa pun)?",
      options: {
        a: "Keuntungan $50,000",
        b: "Kerugian $25,000",
        c: "Kerugian $50,000",
        d: "Keuntungan $25,000",
      },
    },
    "18": {
      id: 18,
      question: "Apa arti rasio risiko-imbalan 5:1?",
      options: {
        a: "Jika Anda berisiko $250, Anda dapat kehilangan $1,250",
        b: "Jika Anda berisiko $350, Anda memiliki kesempatan untuk memenangkan $1,750",
        c: "Anda berpotensi memenangkan $5,000 jika Anda berisiko $25,000",
        d: "Anda berpotensi memenangkan $5,000 jika Anda berisiko $1,000",
      },
    },
    "19": {
      id: 19,
      question: "Ketika berdagang produk leverage, pernyataan mana yang benar?",
      options: {
        a: "Baik keuntungan maupun kerugian dapat diperbesar",
        b: "Hanya keuntungan yang dapat diperbesar",
        c: "Hanya kerugian yang dapat diperbesar",
        d: "Tidak ada yang diperbesar",
      },
    },
    "20": {
      id: 20,
      question: "Mengapa Anda menggunakan stop loss?",
      options: {
        a: "Untuk membuka posisi baru",
        b: "Untuk menghemat biaya transaksi",
        c: "Untuk membatasi kerugian Anda dalam perdagangan",
        d: "Untuk mendapatkan keuntungan maksimum dalam perdagangan",
      },
    },
    "21": {
      id: 21,
      question:
        "Anda memiliki saldo akun saat ini sebesar $50,000, ekuitas akun sebesar $40,000, dan hanya satu posisi terbuka di AUDUSD dengan persyaratan margin sebesar $30,000. Tingkat panggilan margin adalah 100%. Di bawah keadaan berikut, Anda akan menerima panggilan margin?",
      options: {
        a: "Ketika saldo akun Anda lebih rendah dari $40,000",
        b: "Ketika saldo akun Anda lebih rendah dari $50,000",
        c: "Ketika ekuitas akun Anda lebih rendah dari $30,000",
        d: "Ketika ekuitas akun Anda adalah $0",
      },
    },
    "22": {
      id: 22,
      question:
        "Kontrak AUDUSD memiliki persyaratan margin 4%. Anda membuka posisi di AUDUSD dengan nilai nominal sebesar A$100,000. Berapa persyaratan marginnya?",
      options: {
        a: "A$4,000",
        b: "A$40,000",
        c: "A$400",
        d: "A$40",
      },
    },
    "23": {
      id: 23,
      question:
        "Anda memegang posisi long (beli) 3 ons emas spot dolar Australia, dan harga tengah pasar saat ini adalah A$2,200. Jika persyaratan margin untuk perdagangan ini adalah 5%, berapa total persyaratan margin untuk perdagangan ini?",
      options: {
        a: "A$2,200",
        b: "A$330",
        c: "A$3,300",
        d: "A$220",
      },
    },
  },
  "zh-tw": {
    "1": {
      id: 1,
      question: "术语“权益”是指什么？",
      options: {
        a: "权益是您账户余额加上所有持仓的浮动盈亏",
        b: "权益是用于维持所有持仓所需的总保证金金额",
        c: "权益是买价和卖价之间的差距",
        d: "权益是持有所有当前持仓所需的总保证金",
      },
    },
    "2": {
      id: 2,
      question: "什么是“点差”？",
      options: {
        a: "交易的利润",
        b: "交易的损失",
        c: "买价和卖价之间的差距",
        d: "市场开盘价和收盘价之间的差距",
      },
    },
    "3": {
      id: 3,
      question: "在什么情况下可能要求您添加资金到您的账户？",
      options: {
        a: "如果市场对您不利，并导致保证金追加",
        b: "当我平仓获利时",
        c: "当有人建议您因为您在盈利交易而添加资金",
        d: "当有存款促销活动时",
      },
    },
    "4": {
      id: 4,
      question: "当您交易股票差价合约（CFD）时，以下哪种说法是正确的？",
      options: {
        a: "您有权参加公司的年度股东大会",
        b: "相比交易实物股票，您承担的风险较小",
        c: "您不直接以自己的名义拥有相关股票",
        d: "您拥有股票的表决权",
      },
    },
    "5": {
      id: 5,
      question: "以下哪种描述最准确地解释了“跳空”（Gapping）？",
      options: {
        a: "当市场价格在图表上出现尖锐上升或下降的情况下，价格之间出现断开，无交易发生",
        b: "进行大额存款或提款后，您账户余额的变化",
        c: "“跳空”是买价和卖价之间的差距",
        d: "“跳空”是市场开盘价和收盘价之间的差距",
      },
    },
    "6": {
      id: 6,
      question: "谁最终负责监控和管理您的交易账户？",
      options: {
        a: "公司的风险团队",
        b: "我有责任监控和管理我的交易账户",
        c: "我的交易账户不需要管理或监控",
        d: "我的财务顾问应该监控我的交易账户",
      },
    },
    "7": {
      id: 7,
      question: "以下哪个不是主要货币对？",
      options: {
        a: "USDJPY",
        b: "NZDJPY",
        c: "EURUSD",
        d: "USDGBP",
      },
    },
    "8": {
      id: 8,
      question: "什么是市场波动性？",
      options: {
        a: "当价格在一段时间内几乎没有变动或没有变动时",
        b: "当价格在短时间内大幅波动时",
        c: "波动性是衡量您在交易中将获得多少利润的指标",
        d: "当市场崩盘时",
      },
    },
    "9": {
      id: 9,
      question: "用借入的资本进行交易的术语是什么？",
      options: {
        a: "保证金交易",
        b: "盈利交易",
        c: "期权交易",
        d: "股票交易",
      },
    },
    "10": {
      id: 10,
      question: "止损订单（Stop Loss）的作用是什么？",
      options: {
        a: "保证您不会亏钱",
        b: "如果触发止损订单，会给您支付奖金",
        c: "通过以下一个可用价格进入交易来限制您的亏损",
        d: "它会退还您所有的损失",
      },
    },
    "11": {
      id: 11,
      question: "交易杠杆产品的风险包括以下哪些？",
      options: {
        a: "您可能会亏损超过您在账户中存入的资金",
        b: "由于杠杆元素，您可能遭受通常与更大投资相关的损失",
        c: "高杠杆交易可能因为标的资产的价值或水平发生相对较小的变动而出现显著的价值变化",
        d: "所有上述情况",
      },
    },
    "12": {
      id: 12,
      question:
        "您决定以0.74000的汇率买入价值为A$10,000的AUDUSD。几周后，卖出价为0.72000。如果您以0.72000的价格平仓，您将在美元方面获利/亏损多少（不计任何费用或佣金支付）？",
      options: {
        a: "亏损$200",
        b: "获利$200",
        c: "获利$2,000",
        d: "亏损$2,000",
      },
    },
    "13": {
      id: 13,
      question: "以下哪种情况会要求您充值您的账户？",
      options: {
        a: "当您获利时",
        b: "市场对您不利，您接近自动平仓，但您希望保持持仓",
        c: "您的持仓已被平仓，且您不想再开仓",
        d: "当您希望关闭账户并支付所有未结费用时",
      },
    },
    "14": {
      id: 14,
      question: "对于买入（做多）交易，您会在哪里设置止损订单（SL）？",
      options: {
        a: "在您的入场价格上方",
        b: "在您的入场价格下方",
        c: "与止盈价格相同",
        d: "只能在卖出（做空）交易上设置止损订单",
      },
    },
    "15": {
      id: 15,
      question: "在使用保证金交易股票差价合约（CFD）时，以下哪种说法是正确的？",
      options: {
        a: "您将拥有所交易公司的一部分股权",
        b: "您的利润和损失将相对于保证金要求而放大",
        c: "CFD交易将使您有权参与股东投票",
        d: "您将始终从CFD做市商那里获得股息",
      },
    },
    "16": {
      id: 16,
      question:
        "您持有2盎司的黄金多头（买入）持仓，市场中间价目前为1,800美元。如果此交易的保证金要求为5%，那么此交易的总保证金要求是多少？",
      options: {
        a: "90美元",
        b: "180美元",
        c: "900美元",
        d: "36美元",
      },
    },
    "17": {
      id: 17,
      question:
        "原油合约的一手合约规模为1000桶。当前价格为每桶50美元，您决定购买五手合约，总价值为250,000美元（50美元 x 5000桶）。后来，原油价格下跌至每桶45美元，您决定平仓。您的盈利/亏损金额是多少（不计任何费用或佣金支付）？",
      options: {
        a: "盈利50,000美元",
        b: "亏损25,000美元",
        c: "亏损50,000美元",
        d: "盈利25,000美元",
      },
    },
    "18": {
      id: 18,
      question: "5:1的风险-收益比是什么意思？",
      options: {
        a: "如果您冒险250美元，您可能会损失1,250美元",
        b: "如果您冒险350美元，您有机会赢得1,750美元",
        c: "如果您冒险25,000美元，您有机会赢得5,000美元",
        d: "如果您冒险1,000美元，您有机会赢得5,000美元",
      },
    },
    "19": {
      id: 19,
      question: "在交易杠杆产品时，以下哪种说法是正确的？",
      options: {
        a: "利润和损失都可能被放大",
        b: "只有利润可以被放大",
        c: "只有损失可以被放大",
        d: "没有任何放大效果",
      },
    },
    "20": {
      id: 20,
      question: "为什么会使用止损订单？",
      options: {
        a: "开立新的持仓",
        b: "节省交易费用",
        c: "限制您的交易亏损",
        d: "在交易中获取最大利润",
      },
    },
    "21": {
      id: 21,
      question:
        "您当前的账户余额为50,000美元，账户权益为40,000美元，仅有一个AUDUSD的持仓，保证金要求为30,000美元。保证金警戒水平为100%。在以下哪种情况下您会收到保证金警告？",
      options: {
        a: "当您的账户余额低于40,000美元时",
        b: "当您的账户余额低于50,000美元时",
        c: "当您的账户权益低于30,000美元时",
        d: "当您的账户权益为0时",
      },
    },
    "22": {
      id: 22,
      question:
        "AUDUSD合约的保证金要求为4%。您以名义价值为A$100,000的AUDUSD开立一个持仓。此交易的保证金要求是多少？",
      options: {
        a: "A$4,000",
        b: "A$40,000",
        c: "A$400",
        d: "A$40",
      },
    },
    "23": {
      id: 23,
      question:
        "您持有3盎司的澳元现货黄金多头（买入）持仓，市场中间价目前为A$2,200。如果此交易的保证金要求为5%，那么此交易的总保证金要求是多少？",
      options: {
        a: "A$2,200",
        b: "A$330",
        c: "A$3,300",
        d: "A$220",
      },
    },
  },
  "zh-hk": {
    "1": {
      id: 1,
      question: "术语“权益”是指什么？",
      options: {
        a: "权益是您账户余额加上所有持仓的浮动盈亏",
        b: "权益是用于维持所有持仓所需的总保证金金额",
        c: "权益是买价和卖价之间的差距",
        d: "权益是持有所有当前持仓所需的总保证金",
      },
    },
    "2": {
      id: 2,
      question: "什么是“点差”？",
      options: {
        a: "交易的利润",
        b: "交易的损失",
        c: "买价和卖价之间的差距",
        d: "市场开盘价和收盘价之间的差距",
      },
    },
    "3": {
      id: 3,
      question: "在什么情况下可能要求您添加资金到您的账户？",
      options: {
        a: "如果市场对您不利，并导致保证金追加",
        b: "当我平仓获利时",
        c: "当有人建议您因为您在盈利交易而添加资金",
        d: "当有存款促销活动时",
      },
    },
    "4": {
      id: 4,
      question: "当您交易股票差价合约（CFD）时，以下哪种说法是正确的？",
      options: {
        a: "您有权参加公司的年度股东大会",
        b: "相比交易实物股票，您承担的风险较小",
        c: "您不直接以自己的名义拥有相关股票",
        d: "您拥有股票的表决权",
      },
    },
    "5": {
      id: 5,
      question: "以下哪种描述最准确地解释了“跳空”（Gapping）？",
      options: {
        a: "当市场价格在图表上出现尖锐上升或下降的情况下，价格之间出现断开，无交易发生",
        b: "进行大额存款或提款后，您账户余额的变化",
        c: "“跳空”是买价和卖价之间的差距",
        d: "“跳空”是市场开盘价和收盘价之间的差距",
      },
    },
    "6": {
      id: 6,
      question: "谁最终负责监控和管理您的交易账户？",
      options: {
        a: "公司的风险团队",
        b: "我有责任监控和管理我的交易账户",
        c: "我的交易账户不需要管理或监控",
        d: "我的财务顾问应该监控我的交易账户",
      },
    },
    "7": {
      id: 7,
      question: "以下哪个不是主要货币对？",
      options: {
        a: "USDJPY",
        b: "NZDJPY",
        c: "EURUSD",
        d: "USDGBP",
      },
    },
    "8": {
      id: 8,
      question: "什么是市场波动性？",
      options: {
        a: "当价格在一段时间内几乎没有变动或没有变动时",
        b: "当价格在短时间内大幅波动时",
        c: "波动性是衡量您在交易中将获得多少利润的指标",
        d: "当市场崩盘时",
      },
    },
    "9": {
      id: 9,
      question: "用借入的资本进行交易的术语是什么？",
      options: {
        a: "保证金交易",
        b: "盈利交易",
        c: "期权交易",
        d: "股票交易",
      },
    },
    "10": {
      id: 10,
      question: "止损订单（Stop Loss）的作用是什么？",
      options: {
        a: "保证您不会亏钱",
        b: "如果触发止损订单，会给您支付奖金",
        c: "通过以下一个可用价格进入交易来限制您的亏损",
        d: "它会退还您所有的损失",
      },
    },
    "11": {
      id: 11,
      question: "交易杠杆产品的风险包括以下哪些？",
      options: {
        a: "您可能会亏损超过您在账户中存入的资金",
        b: "由于杠杆元素，您可能遭受通常与更大投资相关的损失",
        c: "高杠杆交易可能因为标的资产的价值或水平发生相对较小的变动而出现显著的价值变化",
        d: "所有上述情况",
      },
    },
    "12": {
      id: 12,
      question:
        "您决定以0.74000的汇率买入价值为A$10,000的AUDUSD。几周后，卖出价为0.72000。如果您以0.72000的价格平仓，您将在美元方面获利/亏损多少（不计任何费用或佣金支付）？",
      options: {
        a: "亏损$200",
        b: "获利$200",
        c: "获利$2,000",
        d: "亏损$2,000",
      },
    },
    "13": {
      id: 13,
      question: "以下哪种情况会要求您充值您的账户？",
      options: {
        a: "当您获利时",
        b: "市场对您不利，您接近自动平仓，但您希望保持持仓",
        c: "您的持仓已被平仓，且您不想再开仓",
        d: "当您希望关闭账户并支付所有未结费用时",
      },
    },
    "14": {
      id: 14,
      question: "对于买入（做多）交易，您会在哪里设置止损订单（SL）？",
      options: {
        a: "在您的入场价格上方",
        b: "在您的入场价格下方",
        c: "与止盈价格相同",
        d: "只能在卖出（做空）交易上设置止损订单",
      },
    },
    "15": {
      id: 15,
      question: "在使用保证金交易股票差价合约（CFD）时，以下哪种说法是正确的？",
      options: {
        a: "您将拥有所交易公司的一部分股权",
        b: "您的利润和损失将相对于保证金要求而放大",
        c: "CFD交易将使您有权参与股东投票",
        d: "您将始终从CFD做市商那里获得股息",
      },
    },
    "16": {
      id: 16,
      question:
        "您持有2盎司的黄金多头（买入）持仓，市场中间价目前为1,800美元。如果此交易的保证金要求为5%，那么此交易的总保证金要求是多少？",
      options: {
        a: "90美元",
        b: "180美元",
        c: "900美元",
        d: "36美元",
      },
    },
    "17": {
      id: 17,
      question:
        "原油合约的一手合约规模为1000桶。当前价格为每桶50美元，您决定购买五手合约，总价值为250,000美元（50美元 x 5000桶）。后来，原油价格下跌至每桶45美元，您决定平仓。您的盈利/亏损金额是多少（不计任何费用或佣金支付）？",
      options: {
        a: "盈利50,000美元",
        b: "亏损25,000美元",
        c: "亏损50,000美元",
        d: "盈利25,000美元",
      },
    },
    "18": {
      id: 18,
      question: "5:1的风险-收益比是什么意思？",
      options: {
        a: "如果您冒险250美元，您可能会损失1,250美元",
        b: "如果您冒险350美元，您有机会赢得1,750美元",
        c: "如果您冒险25,000美元，您有机会赢得5,000美元",
        d: "如果您冒险1,000美元，您有机会赢得5,000美元",
      },
    },
    "19": {
      id: 19,
      question: "在交易杠杆产品时，以下哪种说法是正确的？",
      options: {
        a: "利润和损失都可能被放大",
        b: "只有利润可以被放大",
        c: "只有损失可以被放大",
        d: "没有任何放大效果",
      },
    },
    "20": {
      id: 20,
      question: "为什么会使用止损订单？",
      options: {
        a: "开立新的持仓",
        b: "节省交易费用",
        c: "限制您的交易亏损",
        d: "在交易中获取最大利润",
      },
    },
    "21": {
      id: 21,
      question:
        "您当前的账户余额为50,000美元，账户权益为40,000美元，仅有一个AUDUSD的持仓，保证金要求为30,000美元。保证金警戒水平为100%。在以下哪种情况下您会收到保证金警告？",
      options: {
        a: "当您的账户余额低于40,000美元时",
        b: "当您的账户余额低于50,000美元时",
        c: "当您的账户权益低于30,000美元时",
        d: "当您的账户权益为0时",
      },
    },
    "22": {
      id: 22,
      question:
        "AUDUSD合约的保证金要求为4%。您以名义价值为A$100,000的AUDUSD开立一个持仓。此交易的保证金要求是多少？",
      options: {
        a: "A$4,000",
        b: "A$40,000",
        c: "A$400",
        d: "A$40",
      },
    },
    "23": {
      id: 23,
      question:
        "您持有3盎司的澳元现货黄金多头（买入）持仓，市场中间价目前为A$2,200。如果此交易的保证金要求为5%，那么此交易的总保证金要求是多少？",
      options: {
        a: "A$2,200",
        b: "A$330",
        c: "A$3,300",
        d: "A$220",
      },
    },
  },
  "ms-my": {
    "1": {
      id: 1,
      question: "Apakah yang dimaksudkan dengan 'Ekuiti'?",
      options: {
        a: "Ekuiti adalah baki akaun anda ditambah dengan keuntungan atau kerugian terapung daripada semua posisi yang dipegang.",
        b: "Ekuiti adalah jumlah margin keseluruhan yang diperlukan untuk mengekalkan semua posisi yang dipegang.",
        c: "Ekuiti adalah perbezaan antara harga belian dan harga jual.",
        d: "Ekuiti adalah jumlah margin keseluruhan yang diperlukan untuk memegang semua posisi semasa.",
      },
    },
    "2": {
      id: 2,
      question: "Apakah 'Spread'?",
      options: {
        a: "Keuntungan dagangan.",
        b: "Kerugian dagangan.",
        c: "Perbezaan antara harga belian dan harga jual.",
        d: "Perbezaan antara harga pembukaan pasaran dan harga penutupan pasaran.",
      },
    },
    "3": {
      id: 3,
      question:
        "Dalam situasi apa yang mungkin memerlukan anda untuk menambah modal ke dalam akaun anda?",
      options: {
        a: "Jika pasaran bergerak menentang anda dan menyebabkan margin tambahan diperlukan.",
        b: "Apabila saya menutup posisi dengan keuntungan.",
        c: "Apabila seseorang menasihati anda untuk menambah modal kerana dagangan anda menguntungkan.",
        d: "Apabila ada promosi deposit.",
      },
    },
    "4": {
      id: 4,
      question:
        "Apakah yang benar tentang dagangan Kontrak Perbezaan (CFD) saham?",
      options: {
        a: "Anda mempunyai hak untuk menghadiri mesyuarat tahunan syarikat.",
        b: "Berbanding dengan dagangan saham sebenar, risiko yang anda tanggung lebih rendah.",
        c: "Anda tidak memiliki saham berkaitan secara langsung.",
        d: "Anda mempunyai hak mengundi bagi saham tersebut.",
      },
    },
    "5": {
      id: 5,
      question: "Antara berikut, yang paling tepat menjelaskan 'Gapping'?",
      options: {
        a: "Apabila harga pasaran bergerak secara tajam dalam carta, mengakibatkan kesenjangan di antara harga dan tiada dagangan dilakukan.",
        b: "Perubahan baki akaun anda selepas deposit besar atau pengeluaran.",
        c: "Gapping adalah perbezaan antara harga belian dan harga jual.",
        d: "Gapping adalah perbezaan antara harga pembukaan pasaran dan harga penutupan pasaran.",
      },
    },
    "6": {
      id: 6,
      question:
        "Siapakah yang bertanggungjawab memantau dan mengurus akaun dagangan anda?",
      options: {
        a: "Pasukan risiko syarikat.",
        b: "Saya bertanggungjawab memantau dan mengurus akaun dagangan saya sendiri.",
        c: "Akaun dagangan saya tidak memerlukan pengurusan atau pemantauan.",
        d: "Penasihat kewangan saya sepatutnya memantau akaun dagangan saya.",
      },
    },
    "7": {
      id: 7,
      question: "Antara berikut, yang bukan pasangan mata wang utama?",
      options: {
        a: "USDJPY",
        b: "NZDJPY",
        c: "EURUSD",
        d: "USDGBP",
      },
    },
    "8": {
      id: 8,
      question: "Apakah yang dimaksudkan dengan 'Volatiliti pasaran'?",
      options: {
        a: "Apabila harga hampir tidak berubah atau tidak berubah dalam tempoh masa tertentu.",
        b: "Apabila harga berubah secara dramatik dalam tempoh masa yang singkat.",
        c: "Volatiliti adalah petunjuk berapa banyak keuntungan yang akan anda perolehi dalam dagangan anda.",
        d: "Apabila pasaran mengalami kemerosotan yang ketara.",
      },
    },
    "9": {
      id: 9,
      question: "Apakah istilah untuk berdagang dengan modal pinjaman?",
      options: {
        a: "Dagangan margin",
        b: "Dagangan menguntungkan",
        c: "Dagangan pilihan",
        d: "Dagangan saham",
      },
    },
    "10": {
      id: 10,
      question: "Apakah tujuan pesanan 'Stop Loss'?",
      options: {
        a: "Memastikan anda tidak kehilangan wang.",
        b: "Jika pesanan 'Stop Loss' dilaksanakan, anda akan menerima bonus.",
        c: "Menghadkan kerugian dagangan anda dengan memasuki dagangan pada harga yang tersedia.",
        d: "Ia akan mengembalikan semua kerugian anda.",
      },
    },
    "11": {
      id: 11,
      question: "Apakah risiko dagangan produk berleveraj?",
      options: {
        a: "Anda mungkin kehilangan lebih daripada jumlah wang yang anda depositkan dalam akaun anda.",
        b: "Berikutan elemen leveraj, anda mungkin mengalami kerugian yang biasanya berkaitan dengan pelaburan yang lebih besar.",
        c: "Dagangan berleveraj yang tinggi mungkin menyebabkan perubahan nilai yang signifikan berbanding dengan aset yang mendasari.",
        d: "Semua di atas.",
      },
    },
    "12": {
      id: 12,
      question:
        "Anda memutuskan untuk membeli AUDUSD pada kadar 0.74000 dengan nilai nominal A$10,000. Beberapa minggu kemudian, harga jual adalah 0.72000. Jika anda menutup dagangan pada harga 0.72000, berapa keuntungan atau kerugian anda dalam dolar AS (tanpa mengira sebarang kos atau komisen)?",
      options: {
        a: "Kerugian $200",
        b: "Keuntungan $200",
        c: "Keuntungan $2,000",
        d: "Kerugian $2,000",
      },
    },
    "13": {
      id: 13,
      question:
        "Antara berikut, situasi mana yang akan memerlukan anda untuk mengisi semula akaun anda?",
      options: {
        a: "Apabila anda mendapat keuntungan.",
        b: "Pasaran bergerak menentang anda, anda hampir mencapai margin panggilan tetapi ingin memegang posisi.",
        c: "Posisi anda telah ditutup dan anda tidak ingin membuka posisi baru.",
        d: "Apabila anda ingin menutup akaun dan membayar semua yuran tertunggak.",
      },
    },
    "14": {
      id: 14,
      question:
        "Untuk dagangan beli (long), di mana anda akan menetapkan pesanan 'Stop Loss'?",
      options: {
        a: "Di atas harga masuk anda.",
        b: "Di bawah harga masuk anda.",
        c: "Sama dengan harga ambil untung.",
        d: "Hanya boleh menetapkan pesanan 'Stop Loss' untuk dagangan jual (short).",
      },
    },
    "15": {
      id: 15,
      question:
        "Apakah yang benar tentang dagangan CFD saham menggunakan margin?",
      options: {
        a: "Anda akan memiliki sebahagian saham syarikat yang diperdagangkan.",
        b: "Keuntungan dan kerugian anda akan diperbesarkan berbanding dengan keperluan margin.",
        c: "Dagangan CFD akan memberi anda hak untuk mengundi sebagai pemegang saham.",
        d: "Anda akan sentiasa menerima dividen daripada CFD broker.",
      },
    },
    "16": {
      id: 16,
      question:
        "Anda memegang kedudukan beli (long) emas sebanyak 2 ons, dengan harga pasaran tengah $1,800. Jika syarat margin untuk dagangan ini adalah 5%, berapakah margin keseluruhan yang diperlukan untuk dagangan ini?",
      options: {
        a: "$90",
        b: "$180",
        c: "$900",
        d: "$36",
      },
    },
    "17": {
      id: 17,
      question:
        "Satu kontrak minyak mentah mempunyai saiz kontrak 1000 tong. Harga semasa adalah $50 setiap tong, dan anda memutuskan untuk membeli lima kontrak, dengan nilai keseluruhan $250,000 ($50 x 5000 tong). Kemudian, harga minyak turun menjadi $45 setiap tong, dan anda memutuskan untuk menutup dagangan. Berapakah jumlah keuntungan/kerugian anda (tanpa mengira sebarang kos atau komisen)?",
      options: {
        a: "Keuntungan $50,000",
        b: "Kerugian $25,000",
        c: "Kerugian $50,000",
        d: "Keuntungan $25,000",
      },
    },
    "18": {
      id: 18,
      question: "Apakah maksud 5:1 risiko-imbalan?",
      options: {
        a: "Jika anda mengambil risiko $250, anda mungkin akan kehilangan $1,250.",
        b: "Jika anda mengambil risiko $350, anda berpeluang untuk memenangi $1,750.",
        c: "Jika anda mengambil risiko $25,000, anda berpeluang untuk memenangi $5,000.",
        d: "Jika anda mengambil risiko $1,000, anda berpeluang untuk memenangi $5,000.",
      },
    },
    "19": {
      id: 19,
      question: "Apabila berdagang produk berleveraj, apakah yang benar?",
      options: {
        a: "Keuntungan dan kerugian kedua-dua mungkin diperbesarkan.",
        b: "Hanya keuntungan yang mungkin diperbesarkan.",
        c: "Hanya kerugian yang mungkin diperbesarkan.",
        d: "Tiada kesan penguasaan.",
      },
    },
    "20": {
      id: 20,
      question: "Mengapa anda akan menggunakan pesanan 'Stop Loss'?",
      options: {
        a: "Untuk membuka kedudukan baru.",
        b: "Untuk menjimatkan kos dagangan.",
        c: "Untuk menghadkan kerugian dagangan anda.",
        d: "Untuk mencapai keuntungan maksimum dalam dagangan.",
      },
    },
    "21": {
      id: 21,
      question:
        "Baki akaun semasa anda adalah $50,000, dan ekuiti akaun adalah $40,000. Anda hanya mempunyai satu kedudukan AUDUSD, dengan keperluan margin $30,000. Tahap panggilan margin adalah 100%. Dalam situasi berikut, anda akan menerima amaran panggilan margin?",
      options: {
        a: "Apabila baki akaun anda jatuh di bawah $40,000.",
        b: "Apabila baki akaun anda jatuh di bawah $50,000.",
        c: "Apabila ekuiti akaun anda jatuh di bawah $30,000.",
        d: "Apabila ekuiti akaun anda menjadi sifar.",
      },
    },
    "22": {
      id: 22,
      question:
        "Margin yang diperlukan untuk kontrak AUDUSD adalah 4%. Anda membuka kedudukan dengan nilai nominal A$100,000 AUDUSD. Berapakah margin yang diperlukan untuk kedudukan ini?",
      options: {
        a: "A$4,000",
        b: "A$40,000",
        c: "A$400",
        d: "A$40",
      },
    },
    "23": {
      id: 23,
      question:
        "Anda memegang kedudukan beli (long) emas sebanyak 3 ons, dengan harga pasaran tengah A$2,200. Jika syarat margin untuk dagangan ini adalah 5%, berapakah margin keseluruhan yang diperlukan untuk dagangan ini?",
      options: {
        a: "A$2,200",
        b: "A$330",
        c: "A$3,300",
        d: "A$220",
      },
    },
  },
  "mn-mn": {
    "1": {
      id: 1,
      question: "‘Equity’ гэж юу хэлдэг вэ?",
      options: {
        a: "Equity нь таны нээлттэй бүх нээлттэй ажилуудын үлдэгдэл болон (эсвэл ялгаатай) орлогыг хэлнэ.",
        b: "Equity нь бүх нээлттэй ажилыг өөрчлөхийн ажил байршлын хамт хэдийнээр төлсөн дүн байдаг",
        c: "Equity нь худалдан авах болон захиалж авах үнэийн хоорондын зай байна.",
        d: "Equity нь бүх одоогийн нээлттэй ажилуудыг хадгалахын тулд шаардлагатай нийлүүлэгдэх дүн байдаг.",
      },
    },
    "2": {
      id: 2,
      question: "“Spread” гэж юу вэ?",
      options: {
        a: "Ажиллаас орхигдсон өрөвдөл",
        b: "Ажиллаас суутгасан өрөвдөл",
        c: "Нээлттэй болон захиалж авах үнэийн хоорондын ялгаатай зай",
        d: "Маркет нээлттэй болон захиалж авах үнэийн хоорондын ялгаатай зай",
      },
    },
    "3": {
      id: 3,
      question: "Та хэрхэн ажлын дансад шимтгэл хийхийг асууж байна?",
      options: {
        a: "Хэрэв маркет таньтай холбогдсон бол, тэр нь шимтгэл авахад ажиллана.",
        b: "Надад зардал өгсний дараа",
        c: "Хэн нэгэн тань зардал өгдөг бол шимтгэл хийж чадна",
        d: "Зээлийн хөрөнгийнхөө ажиллахийг тайлж байгаа",
      },
    },
    "4": {
      id: 4,
      question: "Хувьцаа CFD-тэй гүйцэтэй үед ямар ялгаатай хэлбэртэй юм бэ?",
      options: {
        a: "Та жилийн томилолтанд оролцох эрхтэй",
        b: "Та худалдан авах хувьцаагаас бага рискийг авах",
        c: "Та үнэндээ хувьцаагаа хүндэтгэнэ",
        d: "Та хувьцаагийн сонголтуудтай холбоотой",
      },
    },
    "5": {
      id: 5,
      question: "Доорхи сонголт нь ‘gapping’-г ямар ч байдалд хамаардаг вэ?",
      options: {
        a: "‘Gapping’ нь график дээрх үнэний хооронд шилжинэ. Энэ нь үнэ нь маркетийн дундаж үнээс ялгаатай зайтай өндөр зэргийн явдалт болох",
        b: "Том нээлттэй буюу том шилжинэлтийн дарааллыг өөрчилсөн дарааллыг хэлнэ",
        c: "‘Gapping’ нь худалдан авах болон захиалж авах үнэний хоорондын ялгаатай зай байна.",
        d: "‘Gapping’ нь маркетийн нээлттэй болон захиалж авах үнэний хоорондын ялгаатай зай байна.",
      },
    },
    "6": {
      id: 6,
      question:
        "Таны ажлын дансанд заагчид ба удирдах чадварыг ямар хэлбэрээр тодорхойлох юм вэ?",
      options: {
        a: "Компанийн рискийн баг",
        b: "Ажлын дансанд заагч болох нь мийн бодлогын хүрээнд нь байна",
        c: "Миний ажлын дансанд заагч болох нь удирдах эсвэл мониторингийг шаарддаггүй",
        d: "Миний санал хураамжийн ажилтан надад заагчинг мониторинглох ёстой",
      },
    },
    "7": {
      id: 7,
      question: "Доорхи сонголтонд ямар нэгэн хамгийн том валют хориотой вэ?",
      options: {
        a: "USDJPY",
        b: "NZDJPY",
        c: "EURUSD",
        d: "USDGBP",
      },
    },
    "8": {
      id: 8,
      question:
        "Маркетийн эргэлтийн үед та ямар ч үед зүгээр байх үед хэдийнээр зайлшгүй байгаа вэ?",
      options: {
        a: "Үргэлжлээ хөгжихгүй эсвэл үлдэгдэлгүй болох үед",
        b: "Бага хугацаанд үргэлжлүүлэгдэж байгаа үед",
        c: "Эргэлтийн хэмжээ нь ажлын төлбөрөөс их болох үед",
        d: "Маркетийн арын хугацаагүй болох үед",
      },
    },
    "9": {
      id: 9,
      question: "Зээлийн хөрөнгийнхөө ажиллахыг тайлбарлахын тэрмин гэж юу вэ?",
      options: {
        a: "Зээлийн ажиллагаа",
        b: "Амжилттай ажиллагаа",
        c: "Сонголтуудын ажиллагаа",
        d: "Хувьцаагийн ажиллагаа",
      },
    },
    "10": {
      id: 10,
      question: "Stop loss гэж юу хийдэг вэ?",
      options: {
        a: "Та зөвхөн гүйлгээ хийхээр гарантилж байгаа",
        b: "Та энэ ажиллагааг хийсний дараа хувиараа хожино олгоно",
        c: "Та хамгийн хурдан үед ажиллахаар дор хаяжаа төлөх боломжтой",
        d: "Та өгөгдсөн зардал бүрэн буцаана",
      },
    },
    "11": {
      id: 11,
      question: "Худалдан авахыг хамгийн их ашиглахын чадвар гэж юу вэ?",
      options: {
        a: "Та өөрчлөх боломжтой байх ажилуудтай ижилхэн мөнгөний төлбөрөө олно.",
        b: "Өрөвдөл элементийн тухай хийж буй үед та ихтэй хэмжээтэй ажиланд төлөх боломжтой байдаг.",
        c: "Хэт ихгүйгээр дамжуулж буй ажиллагааны үнэт цаасыг хадгалах",
        d: "Бүх дээд хохироо дамжуулж буй ажиллагаанууд нь үнэтэй өөрчлөлттэй байдаг.",
      },
    },
    "12": {
      id: 12,
      question:
        "Та AUDUSD-д 0.74000 үнээр A$ 10,000 худалдаж авахаар шийдсэн. Хэдийнээр нь үедээ 0.72000 үнээр худалдаж авахаар шийдэж байгаа. USD-руу хөрвүүлсэн үлдэгдэл/зардал хэд байна (түрээсийн үнэ эсвэл коммиссийн төлбөрийг тооцохгүй)?",
      options: {
        a: "$200 хөрвүүлэх",
        b: "$200 олох",
        c: "$2,000 олох",
        d: "$2,000 хөрвүүлэх",
      },
    },
    "13": {
      id: 13,
      question:
        "Доорхи байдлаар ямар нэгэн үед та таны дансанд зардал оруулах хэрэгтэй вэ?",
      options: {
        a: "Та амжилттай ажиллаж байгаа үед",
        b: "Маркет таньтай холбогдохоор та автоматаар хаахаар хүсч байгаа үед",
        c: "Таны ажлын хэрэгцээг хаасан бөгөөд та ихгүй ажиллаж болох үед",
        d: "Та таны дансанд байгаа бүх харьцуулагчийн зардлуудыг хэрэгжүүлэхийг хүсч байгаа үед",
      },
    },
    "14": {
      id: 14,
      question: "Худалдан авахын тулд та stop loss-г хаана хийж болох вэ?",
      options: {
        a: "Таны оруулж буй үнээс дээш",
        b: "Таны оруулж буй үнээс доош",
        c: "Тухайн төлөвлөсөн үнэтэйдээ",
        d: "Та захиалж буй үнээрээ зөвхөн худалдаж авах ажиллагаа дээр stop loss оруулах боломжтой",
      },
    },
    "15": {
      id: 15,
      question:
        "Хувьцаа CFD-ийг маржинд худалдаж авах үед доорх зөвлөл гэж юу хийдэг вэ?",
      options: {
        a: "Та захиалж авсан компанийн хувьцаагийнхаа хувьд зардлаа олно",
        b: "Таны амжилт болон амьдралд олон хувьцаагийнхаа хувьд нийцэх үлдэгдлийг нэмэгдүүлнэ",
        c: "CFD ажиллахыг холбоотой хувьцаагийн хоёрдахь урсгалыг авч ажиллах боломжтой",
        d: "Та хувьцаагийн үйлчлэл хийгээгүй үед харилцаагүй үлдэгдэлгүй олно",
      },
    },
    "16": {
      id: 16,
      question:
        "Та 2 унцлага худалдаж авсан бөгөөд маркетийн дундаж үнэ нь $1,800 байна. Энэ ажилын хувьд хамгийн доод маржинаас хэдийнээр зай байх вэ?",
      options: {
        a: "$90",
        b: "$180",
        c: "$900",
        d: "$36",
      },
    },
    "17": {
      id: 17,
      question:
        "Хумсны төрлийн өгөгдмөл дэнд 1 хөдөлмөртэй ажиллахын тулд 1000 барелсан өрөвдийн хэмжээтэй. Энэ нь одоогоор $50 захиалагдах бөгөөд та тэг хэдийнээр 5 хөдөлмөр авахаар шийдэх боломжтой. Дараагийн үед хумсны үнийг $45 захиалагдах боломжтой болох хугацаанд тань ажиллах дараа та хэдийнээр олсон болох гаднах чансны урт $/нэг үйлчлэлийг хэлнэ.",
      options: {
        a: "$50,000 авсан",
        b: "$25,000 хөрвүүлсэн",
        c: "$50,000 хөрвүүлсэн",
        d: "$25,000 авсан",
      },
    },
    "18": {
      id: 18,
      question: "5:1 хүчний хэмжээ хэдэн үг вэ?",
      options: {
        a: "Та $250 зардаг бол $1,250 хүчтэй байна",
        b: "Та $350 зардаг бол $1,750 хүчтэй байна",
        c: "Та $25,000 зардаг бол $5,000 хүчтэй байна",
        d: "Та $1,000 зардаг бол $5,000 хүчтэй байна",
      },
    },
    "19": {
      id: 19,
      question:
        "Хүчний хөрөнгийнхөө ажиллахын тулд ямар ялгаатай байдал гэж үзэх вэ?",
      options: {
        a: "Амжилт болон амьдрал хооронд нь ямагт буюу ялгаатай байж болох",
        b: "Зөвхөн амжилт буюу ямагт буюу ялгаатай байж болох",
        c: "Зөвхөн ямагт буюу ялгаатай байж болох",
        d: "Тэгвэл ямагт ялгаатай байж болохгүй",
      },
    },
    "20": {
      id: 20,
      question: "Та ямар ч үед stop loss ашиглахаар шийдэх вэ?",
      options: {
        a: "Шинэ ажлыг эхлүүлэхийн тулд",
        b: "Гүйлгээний төлбөрийг хэрэглэхийн тулд",
        c: "Ажиллахад алдагдах зардал үйлчлэлийг хязгаарлахын тулд",
        d: "Ажиллахад магадгүйгээр их олно",
      },
    },
    "21": {
      id: 21,
      question:
        "Таны одоогийн дансны баланс нь $50,000, дансны хэв маягтай $40,000, ба AUDUSD-д нээлттэй байгаа нээлттэй ажилын зардал $30,000 байна. Маржинаас гарсан болдог түлшний түвшин нь 100% юу вэ?",
      options: {
        a: "Таны дансны баланс 50,000-ээс бага байх үед",
        b: "Таны дансны баланс 50,000-ээс бага байх үед",
        c: "Таны дансны хэв маягт 30,000-ээс бага байх үед",
        d: "Таны дансны хэв маягт $0 болсон үед",
      },
    },
    "22": {
      id: 22,
      question:
        "AUDUSD контракт нь 4% маржин шаардлагатай. Та A$100,000-ийн утгатай нээлттэй ажилыг нээсэн бол маржин шаардлагатай нь хэдэн байна?",
      options: {
        a: "A$4,000",
        b: "A$40,000",
        c: "A$400",
        d: "A$40",
      },
    },
    "23": {
      id: 23,
      question:
        "Та 3 унцлага худалдаж авсан бөгөөд маркетийн дундаж үнэ нь A$2,200 байна. Энэ ажилын хувьд хамгийн доод маржинаас хэдийнээр зай байх вэ?",
      options: {
        a: "A$2,200 олох",
        b: "A$330 олох",
        c: "A$3,300 олох",
        d: "A$220 олох",
      },
    },
  },
};
