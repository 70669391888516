
import { defineComponent, computed } from "vue";
import KTTopbar from "@/layouts/client-layout/header/Topbar.vue";
import KTMenu from "@/layouts/client-layout/header/Menu.vue";

import {
  headerWidthFluid,
  headerFixed,
  headerFixedOnMobile,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import { getTenantLogo } from "@/core/types/TenantTypes";

export default defineComponent({
  name: "KTHeader",
  components: {
    KTTopbar,
    KTMenu,
  },
  setup() {
    const isHeaderSticky = computed(() => {
      if (window.innerWidth > 768) {
        return headerFixed.value;
      } else {
        return headerFixedOnMobile.value;
      }
    });

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      isHeaderSticky,
      getTenantLogo,
    };
  },
});
