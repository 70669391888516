import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app-navbar flex-shrink-0" }
const _hoisted_2 = {
  class: "app-navbar-item ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_3 = {
  class: "cursor-pointer symbol symbol-35px symbol-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_4 = {
  key: 0,
  class: "app-navbar-item d-lg-none ms-2 me-n3",
  title: "Show header menu"
}
const _hoisted_5 = {
  class: "btn btn-icon btn-active-color-primary w-35px h-35px",
  id: "kt_app_header_menu_toggle"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BPWSNotify = _resolveComponent("BPWSNotify")!
  const _component_AuthImage = _resolveComponent("AuthImage")!
  const _component_BPUserMenu = _resolveComponent("BPUserMenu")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BPWSNotify),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AuthImage, {
          imageGuid: _ctx.user.avatar,
          alt: _ctx.user.name
        }, null, 8, ["imageGuid", "alt"])
      ]),
      _createVNode(_component_BPUserMenu)
    ]),
    (_ctx.headerMenuDisplay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "/images/icons/text/txt001.svg" })
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}