import { getLanguage } from "../types/LanguageTypes";
import i18n from "@/core/plugins/i18n";
const { t } = i18n.global;
import { computed } from "vue";
export const prefixLink =
  "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/";

export const baDocs = {
  termAndConditions: {
    title: "termAndConditions",
    src: "BCR-Terms-and-Conditions.pdf",
  },
  accountOpeningDeclarations: {
    title: "accountOpeningDeclarations",
    src: "BCR-Account-Opening-Declarations.pdf",
  },
  privacyPolicy: {
    title: "privacyPolicy",
    src: "BCR-Privacy-Policy.pdf",
  },
  publicComplaintsPolicy: {
    title: "publicComplaintsPolicy",
    src: "BCR-Public-Complaints-Policy.pdf",
  },
  targetMarketDetermination: {
    title: "targetMarketDetermination",
    src: "BCR-Target-Market-Determination.pdf",
  },
  financialServicesGuide: {
    title: "financialServicesGuide",
    src: "BCR-Financial-Services-Guide.pdf",
  },
  productDisclosureDocument: {
    title: "productDisclosureDocument",
    src: "BCR-Product-Disclosure-Statement.pdf",
  },
  contractSpecifications: {
    title: "contractSpecifications",
    src: "https://au.thebcr.com/",
  },
};

export const bviDocs = {
  clientAgreement: {
    title: "clientAgreement",
    src: "BCR-Co-Pty-Ltd-Client-Agreement.pdf",
  },
  accountOpeningDeclarations: {
    title: "accountOpeningDeclarations",
    src: "BCR-Co-Pty-Ltd-Account-Opening-Terms-and-Conditions.pdf",
  },
  orderExcutionPolicy: {
    title: "orderExcutionPolicy",
    src: "BCR-Co-Pty-Ltd-Order-Execution-Policy.pdf",
  },
  contractSpecifications: {
    title: "contractSpecifications",
    src: "https://bvi.thebcr.com/",
  },
  publicComplaintsPolicy: {
    title: "publicComplaintsPolicy",
    src: "BCR-Co-Pty-Ltd-Complaints-Handling-Policy.pdf",
  },
  privacyPolicy: {
    title: "privacyPolicy",
    src: "BCR-Co-Pty-Ltd-Privacy-Policy.pdf",
  },
  websiteTermsOfUse: {
    title: "websiteTermsOfUse",
    src: "BCR-Co-Pty-Ltd-Website-Terms-of-Use.pdf",
  },
  riskDisclosurePolicy: {
    title: "riskDisclosurePolicy",
    src: "BCR-Co-Pty-Ltd-Risk-Disclosure-Policy.pdf",
  },
  preventionOfMoneyLaundering: {
    title: "preventionOfMoneyLaundering",
    src: "BCR-Co-Pty-Ltd-Prevention-of-Money-Laundering-&-Terror-Financing-Manual.pdf",
  },
};

export const jpDocs = {
  foreignExchangeMarginTrading: {
    title: t("type.jpAgreement.foreignExchangeMarginTrading"),
    src: "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/jp/jp-jp/attention_fx_cfd.pdf",
  },
  foreignExchangeTermsAndConditions: {
    title: t("type.jpAgreement.foreignExchangeTermsAndConditions"),
    src: "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/jp/jp-jp/agreement_fx.pdf",
  },

  foreginExchangeTradingManual: {
    title: t("type.jpAgreement.foreginExchangeTradingManual"),
    src: "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/jp/jp-jp/fx_internet.pdf",
  },
  cfdTradingTermsAndConditions: {
    title: t("type.jpAgreement.cfdTradingTermsAndConditions"),
    src: "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/jp/jp-jp/agreement_cfd_s.pdf",
  },
  cfdTradingManual: {
    title: t("type.jpAgreement.cfdTradingManual"),
    src: "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/jp/jp-jp/cfd_s_internet.pdf",
  },
  productCfdTrading: {
    title: t("type.jpAgreement.productCfdTrading"),
    src: "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/jp/jp-jp/attention_cfd.pdf",
  },
  commodityCfdTradingTermsAndConditions: {
    title: t("type.jpAgreement.commodityCfdTradingTermsAndConditions"),
    src: "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/jp/jp-jp/agreement_cfd_p.pdf",
  },
  productCfdTradingManual: {
    title: t("type.jpAgreement.productCfdTradingManual"),
    src: "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/jp/jp-jp/cfd_p_internet.pdf",
  },
  productCfdTradingForCorporations: {
    title: t("type.jpAgreement.productCfdTradingForCorporations"),
    src: "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/jp/jp-jp/c_cfd_corporate.pdf",
  },
  agreementRegardingElectronicDelivery: {
    title: t("type.jpAgreement.agreementRegardingElectronicDelivery"),
    src: "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/jp/jp-jp/consent.pdf",
  },
  personalInformationProtectionDeclaration: {
    title: t("type.jpAgreement.personalInformationProtectionDeclaration"),
    src: "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/jp/jp-jp/kojinjyouhou_hogoseigen2.pdf",
  },
  agreementForAntiSocialForce: {
    title: t("type.jpAgreement.agreementForAntiSocialForce"),
    src: "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/jp/jp-jp/hanshakaitekiseiryoku_doui.pdf",
  },
  notificationOfNotFallingUnderForeignPeps: {
    title: t("type.jpAgreement.notificationOfNotFallingUnderForeignPeps"),
    src: "https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/jp/jp-jp/notificationOfNotFallingUnderForeignPeps.pdf",
  },
};

export const wholeSaleDocs = {
  certificateByQualifiedAccountant: {
    title: "certificateByQualifiedAccountant",
    src: "Certificate-By-a-Qualified-Accountant.pdf",
  },
  wholesaleClientDisclosureNotice: {
    title: "wholesaleClientDisclosureNotice",
    src: "Wholesale-Client-Disclosure-Notice.pdf",
  },
  sophisticatedInvestorAcknowledgement: {
    title: "sophisticatedInvestorAcknowledgement",
    src: "Sophisticated-Investor-Acknowledgement.pdf",
  },
};

const websiteLangCode = {
  "zh-cn": "cn",
  "en-us": "en",
  "id-id": "en",
  "zh-hk": "tw",
  "zh-tw": "tw",
  "ms-my": "ms",
  "th-th": "th",
  "vi-vn": "vi",
  // "mn-mn": "mn",
  "jp-jp": "jp",
  "ko-kr": "ko",
  "km-kh": "km",
  "es-es": "es",
};

// Certificate-By-a-Qualified-Accountant.pdf
//Wholesale-Client-Disclosure-Notice.pdf
//Sophisticated+Investor+Acknowledgement.pdf

const excludeLangBa = ["id-id", "ko-kr", "km-kh"];
export const getBaDocs = (fileName: string) => {
  let lang = getLanguage.value;
  if (excludeLangBa.includes(lang)) {
    lang = "en-us";
  }
  if (fileName == "contractSpecifications") {
    const link =
      baDocs[fileName].src + `${websiteLangCode[lang]}/contract-specifications`;
    return link;
  }
  const file = baDocs[fileName].src;
  return `https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/ba/${lang}/${file}`;
};

const excludeLangBvi = ["id-id"];

export const getBviDocs = (fileName: string) => {
  let lang = getLanguage.value;
  if (excludeLangBvi.includes(lang)) {
    lang = "en-us";
  }
  if (fileName == "contractSpecifications") {
    const link =
      bviDocs[fileName].src +
      `${websiteLangCode[lang]}/contract-specifications`;
    return link;
  }
  const file = bviDocs[fileName].src;
  return `https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/bvi/${lang}/${file}`;
};

export const getWholeSaleDocs = (fileName: string) => {
  let lang = getLanguage.value;
  if (lang != "zh-cn") {
    lang = "en-us";
  }
  const file = wholeSaleDocs[fileName].src;
  return `https://bcrpropublic.s3.ap-southeast-1.amazonaws.com/docs/ba/${lang}/${file}`;
};

export const baVerficationDocs = {
  bcrAccountOpeningDeclarations: baDocs.accountOpeningDeclarations,
  bcrfinancialServiceGuide: baDocs.financialServicesGuide,
  bcrPrivacyPolicy: baDocs.privacyPolicy,
  bcrProductDisclosureStatement: baDocs.productDisclosureDocument,
  bcrPublicComplainPolicy: baDocs.publicComplaintsPolicy,
  bcrTermAndConditions: baDocs.termAndConditions,
  bcrTargetMarketDetermination: baDocs.targetMarketDetermination,
};

export const bviVerficationDocs = {
  BCRCoPtyLtdAccountOpeningTermsandConditions:
    bviDocs.accountOpeningDeclarations,
  BCRCoPtyLtdClientAgreement: bviDocs.clientAgreement,
  BCRCoPtyLtdPreventionofMoneyLaunderingTerrorFinancingManual:
    bviDocs.preventionOfMoneyLaundering,
  BCRCoPtyLtdPrivacyPolicy: bviDocs.privacyPolicy,
  BCRCoPtyLtdRiskDisclosurePolicy: bviDocs.riskDisclosurePolicy,
  BCRCoPtyLtdWebsiteTermsofUse: bviDocs.websiteTermsOfUse,
  BCRCoPtyLtdOrderExecutionPolicy: bviDocs.orderExcutionPolicy,
  bcrComplaintsHandlingPolicy: bviDocs.publicComplaintsPolicy,
};
