
import { defineComponent } from "vue";
// import MenuPages from "@/layouts/backend-layout/header/menu/MenuPages.vue";
import { version } from "@/core/helpers/documentation";
import { headerMenuDisplay } from "../../config/config";

export default defineComponent({
  name: "header-menu",
  components: {
    // MenuPages,
  },
  setup() {
    return {
      version,
      headerMenuDisplay,
    };
  },
});
