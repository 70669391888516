export const weekdayOptions = [
  { label: "Monday", value: "mon" },
  { label: "TuesDay", value: "tue" },
  { label: "Wednesday", value: "wed" },
  { label: "ThursDay", value: "thu" },
  { label: "Friday", value: "fri" },
  { label: "Saturday", value: "sat" },
  { label: "Sunday", value: "sun" },
];

export const bviMargins = [
  { label: "10:1", value: "10:1" },
  { label: "20:1", value: "20:1" },
  { label: "25:1", value: "25:1" },
  { label: "50:1", value: "50:1" },
  { label: "100:1", value: "100:1" },
  { label: "200:1", value: "200:1" },
  { label: "400:1", value: "400:1" },
];
