import MenuItem from "@/core/types/MenuItem";

const menuItems: Array<MenuItem> = [
  {
    heading: "title.trade",
    route: "/trade",
    permissions: ["TenantAdmin"],
    pages: [
      {
        heading: "title.equityCheck",
        route: "/trade/equity-check",
        svgIcon: "/images/icons/general/gen043.svg",
        permissions: ["TenantAdmin"],
      },
      {
        heading: "title.briefDetail",
        route: "/trade/brief-detail",
        svgIcon: "/images/icons/general/gen043.svg",
        permissions: ["TenantAdmin"],
      },
      {
        heading: "title.offsetCheck",
        route: "/trade/offset-check",
        svgIcon: "/images/icons/general/gen043.svg",
        permissions: ["TenantAdmin", "WebOffsetCheck"],
      },
    ],
  },
];

function registerMenu(menu) {
  menu.addMenu(menuItems);
}

export default registerMenu;
