<template>
  <div v-if="$cans(['Guest'])">
    <!--  Mobile Start  -->
    <div class="d-lg-none card mb-5" style="border-top: 4px solid #4196f0">
      <div class="card-body">
        <div class="d-flex mb-3">
          <inline-svg src="/images/icons/other/verify_sm.svg" />
          <h2 class="fw-bold text-gray-800 ms-1">
            {{ $t("tip.verifyYourAccount") }}
          </h2>
        </div>
        <div class="ms-7">
          <p class="text-muted fw-semibold fs-6">
            {{ $t("tip.verificationTip") }}
          </p>
          <router-link to="/verification" class="btn btn-primary fw-semibold">{{
            $t("action.startNow")
          }}</router-link>
        </div>
      </div>
    </div>
    <!-- Mobile End    -->

    <div class="d-none d-lg-block card h-150px card-xl-stretch">
      <div
        class="card-body d-flex align-items-center justify-content-between flex-wrap"
      >
        <img src="/images/icons/other/verify.png" style="width: 72px" />
        <div class="me-2 px-5" style="flex: 1">
          <h2 class="fw-bold text-gray-800 mb-3">
            {{ $t("tip.verifyYourAccount") }}
          </h2>
          <p class="text-muted fw-semibold fs-6">
            {{ $t("tip.verificationTip") }}
          </p>
        </div>
        <router-link to="/verification" class="btn btn-primary fw-semibold">{{
          $t("action.startNow")
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
