import i18n from "@/core/plugins/i18n";
import store from "@/store";
import { computed } from "vue";
import { PublicSetting } from "@/core/types/ConfigTypes";
const { t } = i18n.global;

export enum CurrencyTypes {
  Invalid = -1,
  ALL = 8,
  DZD = 12,
  ARS = 32,
  AUD = 36,
  BSD = 44,
  BHD = 48,
  BDT = 50,
  AMD = 51,
  BBD = 52,
  BMD = 60,
  BTN = 64,
  BOB = 68,
  BWP = 72,
  BZD = 84,
  SBD = 90,
  BND = 96,
  MMK = 104,
  BIF = 108,
  KHR = 116,
  CAD = 124,
  CVE = 132,
  KYD = 136,
  LKR = 144,
  CLP = 152,
  CNY = 156,
  COP = 170,
  KMF = 174,
  CRC = 188,
  HRK = 191,
  CUP = 192,
  CZK = 203,
  DKK = 208,
  DOP = 214,
  SVC = 222,
  ETB = 230,
  ERN = 232,
  FKP = 238,
  FJD = 242,
  DJF = 262,
  GMD = 270,
  GIP = 292,
  GTQ = 320,
  GNF = 324,
  GYD = 328,
  HTG = 332,
  HNL = 340,
  HKD = 344,
  HUF = 348,
  ISK = 352,
  INR = 356,
  IDR = 360,
  IRR = 364,
  IQD = 368,
  ILS = 376,
  JMD = 388,
  JPY = 392,
  KZT = 398,
  JOD = 400,
  KES = 404,
  KPW = 408,
  KRW = 410,
  KWD = 414,
  KGS = 417,
  LAK = 418,
  LBP = 422,
  LSL = 426,
  LRD = 430,
  LYD = 434,
  MOP = 446,
  MWK = 454,
  MYR = 458,
  MVR = 462,
  MUR = 480,
  MXN = 484,
  MNT = 496,
  MDL = 498,
  MAD = 504,
  OMR = 512,
  NAD = 516,
  NPR = 524,
  ANG = 532,
  AWG = 533,
  VUV = 548,
  NZD = 554,
  NIO = 558,
  NGN = 566,
  NOK = 578,
  PKR = 586,
  PAB = 590,
  PGK = 598,
  PYG = 600,
  PEN = 604,
  PHP = 608,
  QAR = 634,
  RUB = 643,
  RWF = 646,
  SHP = 654,
  SAR = 682,
  SCR = 690,
  SLL = 694,
  SGD = 702,
  VND = 704,
  SOS = 706,
  ZAR = 710,
  SSP = 728,
  SZL = 748,
  SEK = 752,
  CHF = 756,
  SYP = 760,
  THB = 764,
  TOP = 776,
  TTD = 780,
  AED = 784,
  TND = 788,
  UGX = 800,
  MKD = 807,
  EGP = 818,
  GBP = 826,
  TZS = 834,
  USD = 840,
  UYU = 858,
  UZS = 860,
  WST = 882,
  YER = 886,
  TWD = 901,
  UYW = 927,
  VES = 928,
  MRU = 929,
  STN = 930,
  CUC = 931,
  ZWL = 932,
  BYN = 933,
  TMT = 934,
  GHS = 936,
  SDG = 938,
  UYI = 940,
  RSD = 941,
  MZN = 943,
  AZN = 944,
  RON = 946,
  CHE = 947,
  CHW = 948,
  TRY = 949,
  XAF = 950,
  XCD = 951,
  XOF = 952,
  XPF = 953,
  ZMW = 967,
  SRD = 968,
  MGA = 969,
  COU = 970,
  AFN = 971,
  TJS = 972,
  AOA = 973,
  BGN = 975,
  CDF = 976,
  BAM = 977,
  EUR = 978,
  MXV = 979,
  UAH = 980,
  GEL = 981,
  BOV = 984,
  PLN = 985,
  BRL = 986,
  CLF = 990,
  USN = 997,
  PTS = 999, // BCR Points
}

export const ConfigCurrencySelections = computed(() => {
  const projectConfig: PublicSetting = store.state.AuthModule.config;
  return projectConfig?.currencyAvailable?.map((currencyId) => ({
    label: t(`type.currency.${currencyId}`),
    value: currencyId,
  }));
});
