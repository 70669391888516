import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { useStore } from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Can from "@/core/plugins/ICan";

import SystemLayout from "@/layouts/SystemLayout.vue";
import AuthLayout from "@/layouts/ClientAuthLayout.vue";
import ClientLayout from "@/layouts/client-layout/ClientLayout.vue";
import ClientDashboard from "../views/ClientDashboard.vue";
import ProfileSetting from "../views/profile/ProfileSetting.vue";
import ProfileBankInfos from "../views/profile/BankInfo.vue";
import ProfileInbox from "@/projects/client/views/profile/ProfileInbox.vue";
import ProfileFileUpload from "@/projects/client/views/profile/ProfileFileUpload.vue";
import AddressSettingVue from "../views/profile/AddressSetting.vue";
import AuthSignOut from "../views/auth/SignOut.vue";

import AuthSignIn from "../views/auth/SignIn.vue";
import AuthSignUp from "../views/auth/SignUp.vue";
import AuthResetPassword from "../views/auth/ResetPassword.vue";
import AuthResetPasswordV2 from "../views/auth/ResetPasswordV2.vue";
import AuthConfirmEmail from "../views/auth/ConfirmEmail.vue";
import AuthOneTimePassword from "../views/auth/OneTimeCode.vue";
import LeadCreate from "../views/auth/LeadCreate.vue";

import Auth2FA from "../views/auth/TwoFA.vue";

import ChangeTradeAccountPassword from "@/projects/client/views/auth/ChangeTradeAccountPassword.vue";
import { computed } from "vue";
import { PublicSetting } from "@/core/types/ConfigTypes";
import CreateDemoAccount from "../views/auth/CreateDemoAccount.vue";
// import Error500 from "@/views/errors/Error500.vue";
import MaintainPage from "@/views/errors/MaintainPage.vue";
import SetToken from "@/projects/client/views/auth/SetToken.vue";
import { setTimerForLogout } from "@/core/plugins/TimerService";
import ClientGlobalService from "@/projects/client/services/ClientGlobalService";

const projectConfig = computed<PublicSetting>(
  () => store.state.AuthModule.config
);
const baseRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/",
    component: ClientLayout,
    children: [
      {
        path: "/",
        name: "dashboard",
        component: ClientDashboard,
        meta: {
          pageTitle: "title.dashboard",
          breadcrumbs: ["title.dashboard"],
          permissions: ["any"],
        },
      },
    ],
  },
];

const profileRoutes: Array<RouteRecordRaw> = [
  {
    path: "/profile",
    redirect: "/profile",
    component: ClientLayout,
    children: [
      {
        path: "/profile",
        name: "setting",
        component: ProfileSetting,
        meta: {
          pageTitle: "title.setting",
          breadcrumbs: ["title.setting"],
          permissions: ["any"],
        },
      },
      {
        path: "/profile/inbox",
        name: "ProfileInbox",
        component: ProfileInbox,
        meta: {
          pageTitle: "title.inbox",
          breadcrumbs: ["title.inbox"],
          permissions: ["any"],
        },
      },
      {
        path: "/profile/bank-infos",
        name: "ProfileBankInfos",
        component: ProfileBankInfos,
        meta: {
          pageTitle: "title.bankInfo",
          breadcrumbs: ["title.bankInfo"],
          permissions: ["any"],
        },
      },
      {
        path: "/profile/file-upload",
        name: "ProfileFileUpload",
        component: ProfileFileUpload,
        meta: {
          pageTitle: "title.fileUpload",
          breadcrumbs: ["title.fileUpload"],
          permissions: ["any"],
        },
      },
      {
        path: "/profile/address",
        name: "AddressSetting",
        component: AddressSettingVue,
        meta: {
          pageTitle: "title.address",
          breadcrumbs: ["title.address"],
          permissions: ["any"],
        },
      },
    ],
  },
];

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: AuthSignIn,
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: AuthSignUp,
        meta: {
          requireAuth: false,
        },
        props: (route) => ({
          code: route.query.code as string,
        }),
      },
      {
        path: "/set-token",
        name: "set-token",
        component: SetToken,
        meta: {
          requireAuth: false,
        },
        props: (route) => ({
          token: route.query.token as string,
        }),
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: AuthResetPassword,
        meta: {
          requireAuth: false,
        },
        props: (route) => ({
          code: route.query.code as string,
        }),
      },
      {
        path: "/reset-password-v2",
        name: "reset-password-v2",
        component: AuthResetPasswordV2,
        meta: {
          requireAuth: false,
        },
        props: (route) => ({
          code: route.query.code as string,
        }),
      },
      {
        path: "/change-account-password/:tenantId",
        name: "change-account-password",
        component: ChangeTradeAccountPassword,
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/demo-account",
        name: "demo-account",
        component: CreateDemoAccount,
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/confirm-email",
        name: "confirm-email",
        component: AuthConfirmEmail,
        meta: {
          requireAuth: false,
        },
        props: (route) => ({
          code: route.query.code as string,
          email: route.query.email as string,
        }),
      },
      {
        path: "/lead-create",
        name: "lead-create",
        component: LeadCreate,
        meta: {
          requireAuth: false,
        },
        props: (route) => ({
          code: route.query.code as string,
        }),
      },
      {
        path: "/2fa",
        name: "2fa",
        component: Auth2FA,
      },
      {
        path: "/sign-out",
        name: "SignOut",
        component: AuthSignOut,
        meta: {
          pageTitle: "title.signOut",
          permissions: ["any"],
        },
      },
      {
        path: "/one-time-password",
        name: "one-time-password",
        component: AuthOneTimePassword,
        meta: {
          requireAuth: false,
        },
      },
      // {
      //   path: "/create-demo-account",
      //   name: "CreateDemoAccount",
      //   component: CreateDemoAccount,
      //   meta: {
      //     pageTitle: "",
      //     requireAuth: false,
      //     permissions: ["any"],
      //   },
      // },
    ],
  },
];
const systemRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: SystemLayout,
    children: [
      {
        path: "/maintain",
        name: "maintain",
        component: MaintainPage,
        meta: {
          permissions: ["any"],
          requireAuth: false,
        },
      },
    ],
  },
];

const routes: Array<RouteRecordRaw> = baseRoutes.concat(
  authRoutes,
  profileRoutes,
  systemRoutes
);

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PATH),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // only for maintain page when system is down
  if (to.name === "maintain") {
    next();
    return;
  }

  const siteStatus = process.env.VUE_APP_SERVICE_STATUS;
  const allowedIps = process.env.VUE_APP_SERVICE_IPS?.split(",") ?? [];
  // siteStatus = "up";
  // siteStatus = "down";

  if (to.name !== "maintain" && siteStatus === "down") {
    const ipInfo = await ClientGlobalService.getCurrentIpInfo();
    if (!allowedIps.includes(ipInfo.ip)) {
      next({ name: "maintain" });
      return;
    }
  }

  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const targetUrlPermissions = to.meta.permissions as string[];
  // const sitePermissions = to.meta.sitePermissions as string[];

  const requireAuth =
    to.meta.requireAuth !== undefined ? (to.meta.requireAuth as boolean) : true;
  if (!requireAuth) {
    next();
    return;
  }

  const isAuth = await store.dispatch(Actions.VERIFY_AUTH);
  if (isAuth) {
    setTimerForLogout();
  }
  if (!targetUrlPermissions) {
    next();
    return;
  }

  if (!isAuth) {
    next({ name: "sign-in" });
    return;
  }

  if (!Can.cans(targetUrlPermissions)) {
    next({ name: "403" });
    return;
  }
  // const tenancy = store.state?.AuthModule?.user?.tenancy;

  // if (sitePermissions && tenancy) {
  //   if (!sitePermissions.includes(tenancy)) {
  //     next({ name: "dashboard" });
  //     return;
  //   }
  // }
  const ibEnabled = projectConfig.value?.ibEnabled;

  if (targetUrlPermissions.includes("IB") && !ibEnabled) {
    next({ name: "404" });
    return;
  }

  next();
});

router.afterEach(() => {
  // gtag("config", "UA-ID", { page_path: to.fullPath });
});

export default router;
