export enum FileFormatTypes {
  CSV = "csv",
  XLSX = "xlsx",
  JSON = "json",
  XML = "xml",
  TXT = "txt",
  HTML = "html",
  PDF = "pdf",
  DOCX = "docx",
  DOC = "doc",
  PNG = "png",
  JPG = "jpg",
  JPEG = "jpeg",
  GIF = "gif",
  SVG = "svg",
  MP4 = "mp4",
  MP3 = "mp3",
  WAV = "wav",
  AVI = "avi",
  MOV = "mov",
  FLV = "flv",
  WMV = "wmv",
  MKV = "mkv",
  ZIP = "zip",
  RAR = "rar",
  TAR = "tar",
  GZ = "gz",
  BZ2 = "bz2",
  _7Z = "7z",
  APK = "apk",
  HEIC = "heic",
}

export const getContentTypeFromMedia = (media) =>
  ({
    ["application/pdf"]: FileFormatTypes.PDF,
    ["image/jpeg"]: FileFormatTypes.JPEG,
    ["image/jpg"]: FileFormatTypes.JPG,
    ["image/png"]: FileFormatTypes.PNG,
    ["text/plain"]: FileFormatTypes.TXT,
    ["text/html"]: FileFormatTypes.HTML,
  }[media?.contentType] ?? FileFormatTypes.JPG);
