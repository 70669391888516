
import { ref, defineComponent, inject, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import {} from "vue-router";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
import { headerMenuIcons } from "@/core/helpers/config";
import { version } from "@/core/helpers/documentation";
import { Actions } from "@/store/enums/StoreEnums";
import MenuItem from "@/core/models/MenuItem";
import { Menu } from "@/core/plugins/menu";
import {
  ILanguage,
  // LanguageCodes,
  LanguageTypes,
} from "@/core/types/LanguageTypes";
import { isMobile } from "@/core/config/WindowConfig";
import { getTenantLanguagesOptions } from "@/core/types/TenantTypes";
export default defineComponent({
  name: "KTMenu",

  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const i18n = useI18n();

    const store = useStore();
    const user = store.state.AuthModule.user;
    const ibEnabled = computed(() => store.state.AuthModule.config?.ibEnabled);

    const mainMenu = inject("mainMenu") as Menu;
    const MainMenuConfig = mainMenu.menus as Array<MenuItem>;
    const tenancy = store.state.AuthModule.user.tenancy;

    const checkShopPermission = (item: any) => {
      if (item.sitePermissions) {
        let showEventShop = ref(false);
        if (item.sitePermissions.includes(tenancy)) {
          showEventShop.value =
            store.state.AuthModule.user.roles.includes("Client");
        }
        return showEventShop.value;
      }
      return true;
    };
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const signOut = () => {
      store.dispatch(Actions.LOGOUT).then(() => {
        router.push({ name: "sign-in" });
        // location.reload();
        //inorder to reset css for line 9 "data-kt-drawer-overlay="true""
        // window.location.href = "sign-in";
      });
    };
    let countries: ILanguage[];
    countries = getTenantLanguagesOptions.value;
    const setLang = async (language: string) => {
      await store.dispatch(Actions.SET_LANG, { language: language });
      i18n.locale.value = language;
    };

    const currentLanguage = (language: string) => {
      return i18n.locale.value === language;
    };

    const currentLanguageLocale = computed(() => {
      return (
        countries.find((x) => x.code === i18n.locale.value) ??
        LanguageTypes.enUS
      );
    });

    return {
      signOut,
      setLang,
      isMobile,
      currentLanguage,
      currentLanguageLocale: currentLanguageLocale,
      countries,
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      translate,
      version,
      user,
      ibEnabled,
      checkShopPermission,
    };
  },
});
