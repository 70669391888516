
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import UserRole from "@/components/UserRole.vue";
import Clipboard from "clipboard";
import JwtService from "@/core/services/JwtService";

import {
  ILanguage,
  LanguageCodes,
  LanguageTypes,
} from "@/core/types/LanguageTypes";

export default defineComponent({
  name: "kt-user-menu",
  components: {
    UserRole,
  },
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();
    const user = store.state.AuthModule.user;
    let storageLanguage = localStorage.getItem("language");
    i18n.locale.value =
      LanguageCodes.all.find((lang) => lang === storageLanguage) ||
      LanguageCodes.enUS;

    let countries: ILanguage[] = LanguageTypes.all;

    const signOut = async () => {
      console.log("logout");
      await router.push({ name: "sign-in" });
      await store.dispatch(Actions.LOGOUT);
    };

    const setLang = (language: string) => {
      store.dispatch(Actions.SET_LANG, { language: language });
      i18n.locale.value = language;
    };

    const currentLanguage = (language: string) => {
      return i18n.locale.value === language;
    };

    const currentLanguageLocale = computed(() => {
      return (
        countries.find((x) => x.code === i18n.locale.value) ??
        LanguageTypes.enUS
      );
    });

    const copyToken = () => {
      Clipboard.copy(JwtService.getToken() as string);
    };

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale: currentLanguageLocale,
      countries,
      user,
      copyToken,
    };
  },
});
