<template>
  <div class="w-100 h-100 d-flex justify-content-center align-items-center">
    <table>
      <tbody>
        <NoDataBox />
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
