import { axiosInstance as axios } from "@/core/services/api.client";
const prefix = "api/v1/client/";
const event = "EventShop";
export default {
  updateOrderAddress: async (orderHashId: string, addressHashId: string) =>
    (
      await axios.put(
        prefix + "event/shop/order/" + orderHashId + "/address/" + addressHashId
      )
    ).data,
  confirmDelivery: async (hashId: string) =>
    (await axios.put(prefix + "event/shop/order/" + hashId + "/succeed")).data,
  queryRewardRebateList: async (criteria?: any) =>
    (await axios.get(prefix + "event/shop/reward/rebate", { params: criteria }))
      .data,

  activateReward: async (hashId: string) =>
    (await axios.put(prefix + "event/shop/reward/" + hashId + "/active")).data,

  queryRewardList: async (criteria?: any) => {
    const params = {
      EventKey: "EventShop",
      ...criteria,
    };

    const response = await axios.get(prefix + "event/shop/reward", { params });
    return response.data;
  },

  queryEventUserDetail: async () =>
    (await axios.get(prefix + "event/" + event + "/user")).data,

  queryEventByKey: async (key: string) =>
    (await axios.get(prefix + "event/" + key)).data,

  registerEventByKey: async (key: string) =>
    (await axios.post(prefix + "event/" + key + "/apply")).data,

  getItemDetail: async (hashId: string) =>
    (await axios.get(prefix + "event/shop/item/" + hashId)).data,

  getItemList: async (criteria?: any) =>
    (await axios.get(prefix + "event/shop/item", { params: criteria })).data,

  purchaseItem: async (formData: any) =>
    (await axios.post(prefix + "event/shop/order", formData)).data,

  purchaseReward: async (formData: any) =>
    (await axios.post(prefix + "event/shop/reward", formData)).data,

  getOrderList: async (criteria?: any) =>
    (await axios.get(prefix + "event/shop/order", { params: criteria })).data,

  getOrderDetail: async (hashId: string) =>
    (await axios.get(prefix + "event/shop/order/" + hashId)).data,

  getShopEvent: async () => (await axios.get(prefix + "event/ShopEvent")).data,

  getPointsHistory: async (criteria?: any) =>
    (
      await axios.get(prefix + "event/shop/point/transaction", {
        params: criteria,
      })
    ).data,
  getImagesWithGuid: async (guid: string) => {
    const response = await axios.get(`${prefix}media/${guid}`, {
      responseType: "blob",
    });
    const imageBlob = new Blob([response.data], { type: "image/jpeg" });
    const imageBlobUrl = URL.createObjectURL(imageBlob);
    return imageBlobUrl;
  },
};
