export enum ZoomTimeZone {
  PMW = "Pacific/Midway",
  PPA = "Pacific/Pago_Pago",
  PHO = "Pacific/Honolulu",
  ANC = "America/Anchorage",
  VAN = "America/Vancouver",
  LAX = "America/Los_Angeles",
  TIJ = "America/Tijuana",
  EDM = "America/Edmonton",
  DEN = "America/Denver",
  PHX = "America/Phoenix",
  MAZ = "America/Mazatlan",
  WPG = "America/Winnipeg",
  REG = "America/Regina",
  CHI = "America/Chicago",
  MEX = "America/Mexico_City",
  GUA = "America/Guatemala",
  SAL = "America/El_Salvador",
  MGA = "America/Managua",
  CRC = "America/Costa_Rica",
  MON = "America/Montreal",
  NYC = "America/New_York",
  IND = "America/Indianapolis",
  PAN = "America/Panama",
  BOG = "America/Bogota",
  LIM = "America/Lima",
  HAL = "America/Halifax",
  PRI = "America/Puerto_Rico",
  CAR = "America/Caracas",
  SNT = "America/Santiago",
  STJ = "America/St_Johns",
  MTV = "America/Montevideo",
  AGA = "America/Araguaina",
  BAI = "America/Argentina/Buenos_Aires",
  GOD = "America/Godthab",
  SAO = "America/Sao_Paulo",
  AZO = "Atlantic/Azores",
  CAT = "Canada/Atlantic",
  CVI = "Atlantic/Cape_Verde",
  UTC = "UTC",
  GRN = "Etc/Greenwich",
  BEL = "Europe/Belgrade",
  CET = "CET",
  REY = "Atlantic/Reykjavik",
  DUB = "Europe/Dublin",
  LON = "Europe/London",
  LIS = "Europe/Lisbon",
  CAS = "Africa/Casablanca",
  NOU = "Africa/Nouakchott",
  OSL = "Europe/Oslo",
  COP = "Europe/Copenhagen",
  BRU = "Europe/Brussels",
  BER = "Europe/Berlin",
  HEL = "Europe/Helsinki",
  AMS = "Europe/Amsterdam",
  ROM = "Europe/Rome",
  STO = "Europe/Stockholm",
  VIE = "Europe/Vienna",
  LUX = "Europe/Luxembourg",
  PAR = "Europe/Paris",
  ZUR = "Europe/Zurich",
  MAD = "Europe/Madrid",
  BAN = "Africa/Bangui",
  ALG = "Africa/Algiers",
  TUN = "Africa/Tunis",
  HAR = "Africa/Harare",
  NAI = "Africa/Nairobi",
  WAR = "Europe/Warsaw",
  PRA = "Europe/Prague",
  BUD = "Europe/Budapest",
  SOF = "Europe/Sofia",
  IST = "Europe/Istanbul",
  ATH = "Europe/Athens",
  BUC = "Europe/Bucharest",
  NIC = "Asia/Nicosia",
  BEI = "Asia/Beirut",
  DAM = "Asia/Damascus",
  JER = "Asia/Jerusalem",
  AMM = "Asia/Amman",
  TRI = "Africa/Tripoli",
  CAI = "Africa/Cairo",
  JNB = "Africa/Johannesburg",
  MOW = "Europe/Moscow",
  BAG = "Asia/Baghdad",
  KWT = "Asia/Kuwait",
  RYD = "Asia/Riyadh",
  BAH = "Asia/Bahrain",
  QAT = "Asia/Qatar",
  ADN = "Asia/Aden",
  THR = "Asia/Tehran",
  KHA = "Africa/Khartoum",
  DJU = "Africa/Djibouti",
  MOG = "Africa/Mogadishu",
  DXB = "Asia/Dubai",
  MUS = "Asia/Muscat",
  BAK = "Asia/Baku",
  KAB = "Asia/Kabul",
  YEK = "Asia/Yekaterinburg",
  TAS = "Asia/Tashkent",
  DEL = "Asia/Calcutta",
  KTM = "Asia/Kathmandu",
  NVS = "Asia/Novosibirsk",
  ALA = "Asia/Almaty",
  DAC = "Asia/Dacca",
  KRA = "Asia/Krasnoyarsk",
  DHK = "Asia/Dhaka",
  BKK = "Asia/Bangkok",
  SGN = "Asia/Saigon",
  JKT = "Asia/Jakarta",
  IRK = "Asia/Irkutsk",
  SHA = "Asia/Shanghai",
  HKG = "Asia/Hong_Kong",
  TPE = "Asia/Taipei",
  KUL = "Asia/Kuala_Lumpur",
  SIN = "Asia/Singapore",
  PER = "Australia/Perth",
  YAK = "Asia/Yakutsk",
  SEL = "Asia/Seoul",
  TOK = "Asia/Tokyo",
  DRW = "Australia/Darwin",
  ADE = "Australia/Adelaide",
  VLA = "Asia/Vladivostok",
  POM = "Pacific/Port_Moresby",
  BNE = "Australia/Brisbane",
  SYD = "Australia/Sydney",
  HBA = "Australia/Hobart",
  MAG = "Asia/Magadan",
  SST = "SST",
  NOUA = "Pacific/Noumea",
  KAM = "Asia/Kamchatka",
  FJI = "Pacific/Fiji",
  AKL = "Pacific/Auckland",
  KOL = "Asia/Kolkata",
  KIE = "Europe/Kiev",
  TEG = "America/Tegucigalpa",
  API = "Pacific/Apia",
}
