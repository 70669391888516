
import { defineComponent } from "vue";

export default defineComponent({
  name: "it-icon-list",
  components: {},
  setup() {
    const icons = [
      "/abstract/abs001.svg",
      "/abstract/abs002.svg",
      "/abstract/abs003.svg",
      "/abstract/abs004.svg",
      "/abstract/abs005.svg",
      "/abstract/abs006.svg",
      "/abstract/abs007.svg",
      "/abstract/abs008.svg",
      "/abstract/abs009.svg",
      "/abstract/abs010.svg",
      "/abstract/abs011.svg",
      "/abstract/abs012.svg",
      "/abstract/abs013.svg",
      "/abstract/abs014.svg",
      "/abstract/abs015.svg",
      "/abstract/abs016.svg",
      "/abstract/abs017.svg",
      "/abstract/abs018.svg",
      "/abstract/abs019.svg",
      "/abstract/abs020.svg",
      "/abstract/abs021.svg",
      "/abstract/abs022.svg",
      "/abstract/abs023.svg",
      "/abstract/abs024.svg",
      "/abstract/abs025.svg",
      "/abstract/abs026.svg",
      "/abstract/abs027.svg",
      "/abstract/abs028.svg",
      "/abstract/abs029.svg",
      "/abstract/abs030.svg",
      "/abstract/abs031.svg",
      "/abstract/abs032.svg",
      "/abstract/abs033.svg",
      "/abstract/abs034.svg",
      "/abstract/abs035.svg",
      "/abstract/abs036.svg",
      "/abstract/abs037.svg",
      "/abstract/abs038.svg",
      "/abstract/abs039.svg",
      "/abstract/abs040.svg",
      "/abstract/abs041.svg",
      "/abstract/abs042.svg",
      "/abstract/abs043.svg",
      "/abstract/abs044.svg",
      "/abstract/abs045.svg",
      "/abstract/abs046.svg",
      "/abstract/abs047.svg",
      "/abstract/abs048.svg",
      "/abstract/abs049.svg",
      "/abstract/abs050.svg",
      "/abstract/abs051.svg",
      "/abstract/abs052.svg",
      "/arrows/arr001.svg",
      "/arrows/arr002.svg",
      "/arrows/arr003.svg",
      "/arrows/arr004.svg",
      "/arrows/arr005.svg",
      "/arrows/arr006.svg",
      "/arrows/arr007.svg",
      "/arrows/arr008.svg",
      "/arrows/arr009.svg",
      "/arrows/arr010.svg",
      "/arrows/arr011.svg",
      "/arrows/arr012.svg",
      "/arrows/arr013.svg",
      "/arrows/arr014.svg",
      "/arrows/arr015.svg",
      "/arrows/arr016.svg",
      "/arrows/arr017.svg",
      "/arrows/arr018.svg",
      "/arrows/arr019.svg",
      "/arrows/arr020.svg",
      "/arrows/arr021.svg",
      "/arrows/arr022.svg",
      "/arrows/arr023.svg",
      "/arrows/arr024.svg",
      "/arrows/arr025.svg",
      "/arrows/arr026.svg",
      "/arrows/arr027.svg",
      "/arrows/arr028.svg",
      "/arrows/arr029.svg",
      "/arrows/arr030.svg",
      "/arrows/arr031.svg",
      "/arrows/arr032.svg",
      "/arrows/arr033.svg",
      "/arrows/arr034.svg",
      "/arrows/arr035.svg",
      "/arrows/arr036.svg",
      "/arrows/arr037.svg",
      "/arrows/arr038.svg",
      "/arrows/arr039.svg",
      "/arrows/arr040.svg",
      "/arrows/arr041.svg",
      "/arrows/arr042.svg",
      "/arrows/arr043.svg",
      "/arrows/arr044.svg",
      "/arrows/arr045.svg",
      "/arrows/arr046.svg",
      "/arrows/arr047.svg",
      "/arrows/arr048.svg",
      "/arrows/arr049.svg",
      "/arrows/arr050.svg",
      "/arrows/arr051.svg",
      "/arrows/arr052.svg",
      "/arrows/arr053.svg",
      "/arrows/arr054.svg",
      "/arrows/arr055.svg",
      "/arrows/arr056.svg",
      "/arrows/arr057.svg",
      "/arrows/arr058.svg",
      "/arrows/arr059.svg",
      "/arrows/arr060.svg",
      "/arrows/arr061.svg",
      "/arrows/arr062.svg",
      "/arrows/arr063.svg",
      "/arrows/arr064.svg",
      "/arrows/arr065.svg",
      "/arrows/arr066.svg",
      "/arrows/arr067.svg",
      "/arrows/arr068.svg",
      "/arrows/arr069.svg",
      "/arrows/arr070.svg",
      "/arrows/arr071.svg",
      "/arrows/arr072.svg",
      "/arrows/arr073.svg",
      "/arrows/arr074.svg",
      "/arrows/arr075.svg",
      "/arrows/arr076.svg",
      "/arrows/arr077.svg",
      "/arrows/arr078.svg",
      "/arrows/arr079.svg",
      "/arrows/arr080.svg",
      "/arrows/arr081.svg",
      "/arrows/arr082.svg",
      "/arrows/arr084.svg",
      "/arrows/arr085.svg",
      "/arrows/arr086.svg",
      "/arrows/arr087.svg",
      "/arrows/arr088.svg",
      "/arrows/arr089.svg",
      "/arrows/arr090.svg",
      "/arrows/arr091.svg",
      "/arrows/arr092.svg",
      "/arrows/arr093.svg",
      "/arrows/arr094.svg",
      "/arrows/arr095.svg",
      "/arrows/arr096.svg",
      "/art/art001.svg",
      "/art/art002.svg",
      "/art/art003.svg",
      "/art/art004.svg",
      "/art/art005.svg",
      "/art/art006.svg",
      "/art/art007.svg",
      "/art/art008.svg",
      "/art/art009.svg",
      "/art/art010.svg",
      "/coding/cod001.svg",
      "/coding/cod002.svg",
      "/coding/cod003.svg",
      "/coding/cod004.svg",
      "/coding/cod005.svg",
      "/coding/cod006.svg",
      "/coding/cod007.svg",
      "/coding/cod008.svg",
      "/coding/cod009.svg",
      "/coding/cod010.svg",
      "/communication/com001.svg",
      "/communication/com002.svg",
      "/communication/com003.svg",
      "/communication/com004.svg",
      "/communication/com005.svg",
      "/communication/com006.svg",
      "/communication/com007.svg",
      "/communication/com008.svg",
      "/communication/com009.svg",
      "/communication/com010.svg",
      "/communication/com011.svg",
      "/communication/com012.svg",
      "/communication/com013.svg",
      "/communication/com014.svg",
      "/ecommerce/ecm001.svg",
      "/ecommerce/ecm002.svg",
      "/ecommerce/ecm003.svg",
      "/ecommerce/ecm004.svg",
      "/ecommerce/ecm005.svg",
      "/ecommerce/ecm006.svg",
      "/ecommerce/ecm007.svg",
      "/ecommerce/ecm008.svg",
      "/ecommerce/ecm009.svg",
      "/ecommerce/ecm010.svg",
      "/ecommerce/ecm011.svg",
      "/electronics/elc001.svg",
      "/electronics/elc002.svg",
      "/electronics/elc003.svg",
      "/electronics/elc004.svg",
      "/electronics/elc005.svg",
      "/electronics/elc006.svg",
      "/electronics/elc007.svg",
      "/electronics/elc008.svg",
      "/electronics/elc009.svg",
      "/electronics/elc010.svg",
      "/files/fil001.svg",
      "/files/fil002.svg",
      "/files/fil003.svg",
      "/files/fil004.svg",
      "/files/fil005.svg",
      "/files/fil006.svg",
      "/files/fil007.svg",
      "/files/fil008.svg",
      "/files/fil009.svg",
      "/files/fil010.svg",
      "/files/fil011.svg",
      "/files/fil012.svg",
      "/files/fil013.svg",
      "/files/fil014.svg",
      "/files/fil015.svg",
      "/files/fil016.svg",
      "/files/fil017.svg",
      "/files/fil018.svg",
      "/files/fil019.svg",
      "/files/fil020.svg",
      "/files/fil021.svg",
      "/files/fil022.svg",
      "/files/fil023.svg",
      "/files/fil024.svg",
      "/files/fil025.svg",
      "/finance/fin001.svg",
      "/finance/fin002.svg",
      "/finance/fin003.svg",
      "/finance/fin004.svg",
      "/finance/fin005.svg",
      "/finance/fin006.svg",
      "/finance/fin007.svg",
      "/finance/fin008.svg",
      "/finance/fin009.svg",
      "/finance/fin010.svg",
      "/general/gen001.svg",
      "/general/gen002.svg",
      "/general/gen003.svg",
      "/general/gen004.svg",
      "/general/gen005.svg",
      "/general/gen006.svg",
      "/general/gen007.svg",
      "/general/gen008.svg",
      "/general/gen009.svg",
      "/general/gen010.svg",
      "/general/gen011.svg",
      "/general/gen012.svg",
      "/general/gen013.svg",
      "/general/gen014.svg",
      "/general/gen015.svg",
      "/general/gen016.svg",
      "/general/gen017.svg",
      "/general/gen018.svg",
      "/general/gen019.svg",
      "/general/gen020.svg",
      "/general/gen021.svg",
      "/general/gen022.svg",
      "/general/gen023.svg",
      "/general/gen024.svg",
      "/general/gen025.svg",
      "/general/gen026.svg",
      "/general/gen027.svg",
      "/general/gen028.svg",
      "/general/gen029.svg",
      "/general/gen030.svg",
      "/general/gen031.svg",
      "/general/gen032.svg",
      "/general/gen033.svg",
      "/general/gen034.svg",
      "/general/gen035.svg",
      "/general/gen036.svg",
      "/general/gen037.svg",
      "/general/gen038.svg",
      "/general/gen039.svg",
      "/general/gen040.svg",
      "/general/gen041.svg",
      "/general/gen042.svg",
      "/general/gen043.svg",
      "/general/gen044.svg",
      "/general/gen045.svg",
      "/general/gen046.svg",
      "/general/gen047.svg",
      "/general/gen048.svg",
      "/general/gen049.svg",
      "/general/gen050.svg",
      "/general/gen051.svg",
      "/general/gen052.svg",
      "/general/gen053.svg",
      "/general/gen054.svg",
      "/general/gen055.svg",
      "/general/gen056.svg",
      "/general/gen057.svg",
      "/general/gen058.svg",
      "/general/gen059.svg",
      "/general/gen060.svg",
      "/general/gen061.svg",
      "/general/gen062.svg",
      "/graphs/gra001.svg",
      "/graphs/gra002.svg",
      "/graphs/gra003.svg",
      "/graphs/gra004.svg",
      "/graphs/gra005.svg",
      "/graphs/gra006.svg",
      "/graphs/gra007.svg",
      "/graphs/gra008.svg",
      "/graphs/gra009.svg",
      "/graphs/gra010.svg",
      "/graphs/gra011.svg",
      "/graphs/gra012.svg",
      "/layouts/lay001.svg",
      "/layouts/lay002.svg",
      "/layouts/lay003.svg",
      "/layouts/lay004.svg",
      "/layouts/lay005.svg",
      "/layouts/lay006.svg",
      "/layouts/lay007.svg",
      "/layouts/lay008.svg",
      "/layouts/lay009.svg",
      "/layouts/lay010.svg",
      "/maps/map001.svg",
      "/maps/map002.svg",
      "/maps/map003.svg",
      "/maps/map004.svg",
      "/maps/map005.svg",
      "/maps/map006.svg",
      "/maps/map007.svg",
      "/maps/map008.svg",
      "/maps/map009.svg",
      "/maps/map010.svg",
      "/medicine/med001.svg",
      "/medicine/med002.svg",
      "/medicine/med003.svg",
      "/medicine/med004.svg",
      "/medicine/med005.svg",
      "/medicine/med006.svg",
      "/medicine/med007.svg",
      "/medicine/med008.svg",
      "/medicine/med009.svg",
      "/medicine/med010.svg",
      "/social/soc001.svg",
      "/social/soc002.svg",
      "/social/soc003.svg",
      "/social/soc004.svg",
      "/social/soc005.svg",
      "/social/soc006.svg",
      "/social/soc007.svg",
      "/social/soc008.svg",
      "/social/soc009.svg",
      "/social/soc010.svg",
      "/technology/teh001.svg",
      "/technology/teh002.svg",
      "/technology/teh003.svg",
      "/technology/teh004.svg",
      "/technology/teh005.svg",
      "/technology/teh006.svg",
      "/technology/teh007.svg",
      "/technology/teh008.svg",
      "/technology/teh009.svg",
      "/technology/teh010.svg",
      "/text/txt001.svg",
      "/text/txt002.svg",
      "/text/txt003.svg",
      "/text/txt004.svg",
      "/text/txt005.svg",
      "/text/txt006.svg",
      "/text/txt007.svg",
      "/text/txt008.svg",
      "/text/txt009.svg",
      "/text/txt010.svg",
      "/stock/Asset Allocation.svg",
      "/stock/Bear Market.svg",
      "/stock/Blue Chip Stock.svg",
      "/stock/Bull Market.svg",
      "/stock/Dividend.svg",
      "/stock/Economic Indicator.svg",
      "/stock/Equity Market.svg",
      "/stock/Financial Graph.svg",
      "/stock/Financial News.svg",
      "/stock/Financial Portfolio.svg",
      "/stock/Fundamental Analysis.svg",
      "/stock/Initial Public Offering.svg",
      "/stock/Investment Portfolio.svg",
      "/stock/Investment Strategy.svg",
      "/stock/Investment.svg",
      "/stock/Market Analysis.svg",
      "/stock/Market Capitalization.svg",
      "/stock/Market Index.svg",
      "/stock/Market Liquidity.svg",
      "/stock/Market Order.svg",
      "/stock/Market Performance.svg",
      "/stock/Market Trend.svg",
      "/stock/Market Volatility.svg",
      "/stock/Market Watch.svg",
      "/stock/Price Fluctuation.svg",
      "/stock/Risk Management.svg",
      "/stock/Sharia Stock.svg",
      "/stock/Stock Chart.svg",
      "/stock/Stock Exchange.svg",
      "/stock/Stock Share Certificate.svg",
      "/finance2/001-payment.svg",
      "/finance2/002-exchange.svg",
      "/finance2/003-notes.svg",
      "/finance2/004-currency.svg",
      "/finance2/005-strongbox.svg",
      "/finance2/006-cheque.svg",
      "/finance2/007-credit.svg",
      "/finance2/008-investment.svg",
      "/finance2/009-barcode.svg",
      "/finance2/010-gold-ingots.svg",
      "/finance2/011-bitcoins.svg",
      "/finance2/012-accounting.svg",
      "/finance2/013-wallet.svg",
      "/finance2/014-mortgage.svg",
      "/finance2/015-currency-1.svg",
      "/finance2/016-atm.svg",
      "/finance2/017-store.svg",
      "/finance2/018-contract.svg",
      "/finance2/019-business.svg",
      "/finance2/020-presentation.svg",
      "/finance2/021-abacus.svg",
      "/finance2/022-diamond.svg",
      "/finance2/023-invoice.svg",
      "/finance2/024-safebox.svg",
      "/finance2/025-growth.svg",
      "/finance2/026-loss.svg",
      "/finance2/027-money-bag.svg",
      "/finance2/028-statistics.svg",
      "/finance2/029-bill.svg",
      "/finance2/030-letter.svg",
      "/finance2/031-affiliate.svg",
      "/finance2/032-refund.svg",
      "/finance2/033-profit.svg",
      "/finance2/034-agreement.svg",
      "/finance2/035-tax.svg",
      "/finance2/036-pyramid.svg",
      "/finance2/037-appraisal.svg",
      "/finance2/038-receipt.svg",
      "/finance2/039-cash-machine.svg",
      "/finance2/040-savings.svg",
      "/finance2/041-sale.svg",
      "/finance2/042-bank.svg",
      "/finance2/043-business-woman.svg",
      "/finance2/044-cashier.svg",
      "/finance2/045-charity.svg",
      "/finance2/046-budget.svg",
      "/finance2/047-tag.svg",
      "/finance2/048-bank-1.svg",
      "/finance2/049-calculator.svg",
      "/finance2/050-auction.svg",
    ];
    return {
      icons,
    };
  },
});
