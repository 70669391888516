
import { defineComponent } from "vue";
import { getIllustrationsPath } from "@/core/helpers/assets";

export default defineComponent({
  name: "error-403",
  components: {},
  setup() {
    return {
      getIllustrationsPath,
    };
  },
});
