type UserGuide = {
  name?: string;
  src: string;
};

export const userGuideList: Record<string, UserGuide> = {
  0: {
    name: "User Guide",
    src: "https://customer-5zhaifhiitxgc8xm.cloudflarestream.com/f4321c71979eb5eb914e34b14a1f6ce8/iframe?poster=https%3A%2F%2Fcustomer-5zhaifhiitxgc8xm.cloudflarestream.com%2Ff4321c71979eb5eb914e34b14a1f6ce8%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600",
  },
  1: {
    name: "IB Guide",
    src: "https://customer-5zhaifhiitxgc8xm.cloudflarestream.com/7ab45de7dfbef52d078a399625e77283/iframe?poster=https%3A%2F%2Fcustomer-5zhaifhiitxgc8xm.cloudflarestream.com%2F7ab45de7dfbef52d078a399625e77283%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600",
  },
};
