
import { defineComponent } from "vue";
import BPUserMenu from "@/layouts/backend-layout/menus/UserAccountMenu.vue";
import BPWSNotify from "@/layouts/backend-layout/notify/WSNotify.vue";
import AuthImage from "@/components/AuthImage.vue";
import { useStore } from "@/store";
import { headerMenuDisplay } from "../config/config";

export default defineComponent({
  name: "header-navbar",
  components: {
    BPUserMenu,
    BPWSNotify,
    AuthImage,
  },
  setup() {
    const store = useStore();
    const user = store.state.AuthModule.user;
    return {
      user,
      headerMenuDisplay,
    };
  },
});
