import BackendLayout from "@/layouts/backend-layout/BackendLayout.vue";
import EmailTemplate from "./views/EmailTemplate.vue";
import NoticeTopic from "./views/NoticeTopic.vue";
export default (router) => {
  router.addRoute({
    path: "/topic",
    redirect: "/topic/notices",
    component: BackendLayout,
    name: "topic",
    children: [
      {
        path: "/topic/notices",
        name: "Notice",
        component: NoticeTopic,
        meta: {
          pageTitle: "title.notices",
          breadcrumbs: ["title.notices"],
          permissions: ["TenantAdmin", "WebNotice"],
        },
      },
      {
        path: "/topic/email-template",
        name: "Email",
        component: EmailTemplate,
        meta: {
          pageTitle: "title.emailTemplate",
          breadcrumbs: ["title.emailTemplate"],
          permissions: ["TenantAdmin", "WebEmailTemplate"],
        },
      },
    ],
  });
  router.removeRoute("catchAll");
  router.addRoute({
    name: "catchAll",
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  });
};
