<template>
  <!--begin::Footer-->
  <div
    class="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6"
    id="kt_app_sidebar_footer"
  ></div>
  <!--end::Footer-->
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "sidebar-footer",
  components: {},
});
</script>
