import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-kt-sticky"]
const _hoisted_2 = {
  class: "d-flex topbar align-items-center d-lg-none ms-n2 me-3",
  title: "Show aside menu"
}
const _hoisted_3 = {
  class: "btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px",
  id: "kt_header_menu_mobile_toggle"
}
const _hoisted_4 = { class: "svg-icon svg-icon-1" }
const _hoisted_5 = { class: "header-logo me-md-10 flex-grow-1 flex-lg-grow-0 text-lg-start text-center" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "d-flex align-items-stretch justify-content-between d-md-none" }
const _hoisted_8 = {
  key: 0,
  class: "header-logo px-4 align-items-center cursor-pointer"
}
const _hoisted_9 = { class: "svg-icon svg-icon-b-2 logo-default" }
const _hoisted_10 = { class: "svg-icon svg-icon-b-2 logo-sticky" }
const _hoisted_11 = { class: "d-flex align-items-stretch justify-content-between flex-lg-grow-1" }
const _hoisted_12 = {
  class: "d-flex align-items-stretch",
  id: "kt_header_nav"
}
const _hoisted_13 = { class: "d-flex align-items-stretch flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_KTMenu = _resolveComponent("KTMenu")!
  const _component_KTTopbar = _resolveComponent("KTTopbar")!

  return (_openBlock(), _createElementBlock("div", {
    id: "kt_header",
    class: "header align-items-stretch",
    "data-kt-sticky": _ctx.isHeaderSticky,
    "data-kt-sticky-name": "header",
    "data-kt-sticky-offset": "{default: '200px', lg: '300px'}"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'container-fluid': _ctx.headerWidthFluid,
        'container-xxl': !_ctx.headerWidthFluid,
      }, "d-flex align-items-center"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "/images/icons/abstract/abs015.svg" })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.getTenantLogo['src'],
              class: "logo-default",
              style: _normalizeStyle(_ctx.getTenantLogo['style'])
            }, null, 12, _hoisted_6)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.$can('Client'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_router_link, { to: "/profile/inbox" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_component_inline_svg, { src: "/images/icons/general/ring_white.svg" })
                  ]),
                  _createElementVNode("span", _hoisted_10, [
                    _createVNode(_component_inline_svg, { src: "/images/icons/general/ring_black.svg" })
                  ])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_KTMenu)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_KTTopbar)
        ])
      ])
    ], 2)
  ], 8, _hoisted_1))
}