export enum RoleTypes {
  SuperAdmin = "SuperAdmin",
  TenantAdmin = "TenantAdmin",
  Client = "Client",
  Sales = "Sales",
  IB = "IB",
}

export enum Fruites {
  Apple = 1,
  Banana = 2,
  Orange = 3,
  Cucumber = 4,
}

export enum UserRoleTypes {
  System = 1,
  SuperAdmin = 2,
  TenantAdmin = 10,
  Compliance = 20,
  KycOfficer = 21,
  Sales = 100,
  Rep = 110,
  Broker = 200,
  IB = 300,
  Wholesale = 310,
  Client = 400,
  Guest = 1000,
}
