import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "kt_app_content",
  class: "app-content flex-column-fluid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "kt_app_content_container",
      class: _normalizeClass(["app-container", {
        'container-fluid': _ctx.contentWidthFluid,
        'container-xxl': !_ctx.contentWidthFluid,
      }])
    }, [
      _createVNode(_component_router_view)
    ], 2)
  ]))
}